import * as fetch from '../../../../_shared/fetch';
import { workpapersSpreadsheetModelHostName } from '../../../../../configs/params';

export async function getSourceDataCells(linkedOutputIds) {
  const spreadsheetServiceUrl = `${workpapersSpreadsheetModelHostName}`;
  const response = await fetch.post(`${spreadsheetServiceUrl}/datareferences/data-flows/linked-workpapers`, {
    body: JSON.stringify(linkedOutputIds),
  });
  const data = await response.json();

  return data;
}
