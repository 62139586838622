const commonFunctions = {
  SPLIT: {
    placeholderParams: ['text', 'delimiter', 'position'],
    description: (
      <>
        Splits the text into several parts based on one or more characters that you specify. The function is
        case-insensitive.
      </>
    ),
    parameters: [
      {
        name: 'text',
        description: <>Required. The text or [field name] that you want to split.</>,
      },
      {
        name: 'delimiter',
        description: <>Required. One or more characters that you want to use to split the text.</>,
      },
      {
        name: 'position',
        description: (
          <>
            <p>
              Required. The delimiter splits the text into several parts and the position is the part you want to keep.
              For example, if you had GL code like 99-123-345-1000 and used “-” as the delimiter and “3” as the
              position, the result would be 345.
            </p>

            <p>
              If there are not enough parts in the split text to satisfy the{' '}
              <em>
                <strong>position</strong>
              </em>
              , no text will be returned.
            </p>
          </>
        ),
      },
    ],
    example: {
      title: 'Examples',
      formulaList: [
        'SPLIT([Account Description],"-",1)',
        'SPLIT([Account Description],"-",2)',
        'SPLIT([Account Description]," ",1)',
      ],
    },
  },
  LEFT: {
    placeholderParams: ['text', 'num_chars'],
    description: <>Returns the first character or characters in text, based on the number of characters you specify.</>,
    parameters: [
      {
        name: 'text',
        description: <>Required. The text or [field name] containing the characters you want to extract.</>,
      },
      {
        name: 'num_chars',
        description: (
          <>
            <p>Required. Specifies the number of characters you want to extract.</p>

            <p>
              If{' '}
              <em>
                <strong>num_chars</strong>
              </em>{' '}
              is greater than the length of{' '}
              <em>
                <strong>text</strong>
              </em>
              , the entire text is returned.
            </p>
          </>
        ),
      },
    ],
    example: {
      title: 'Example',
      formulaList: ['LEFT([Account Description],5)'],
    },
  },
  RIGHT: {
    placeholderParams: ['text', 'num_chars'],
    description: <>Returns the last character or characters in text, based on the number of characters you specify.</>,
    parameters: [
      {
        name: 'text',
        description: <>Required. The text or [field name] containing the characters you want to extract.</>,
      },
      {
        name: 'num_chars',
        description: (
          <>
            <p>Required. Specifies the number of characters you want to extract.</p>

            <p>
              If{' '}
              <em>
                <strong>num_chars</strong>
              </em>{' '}
              is greater than the length of{' '}
              <em>
                <strong>text</strong>
              </em>
              , the entire text is returned.
            </p>
          </>
        ),
      },
    ],
    example: {
      title: 'Example',
      formulaList: ['RIGHT([Account Description],5)'],
    },
  },
  MID: {
    placeholderParams: ['text', 'position', 'num_chars'],
    description: (
      <>
        Returns a specific number of characters from text, starting at the position you specify, based on the number of
        characters you specify.
      </>
    ),
    parameters: [
      {
        name: 'text',
        description: <>Required. The text or [field name] containing the characters you want to extract.</>,
      },
      {
        name: 'position',
        description: (
          <>
            <p>
              Required. The numeric position of the first character you want to extract in the text. For example, 6
              indicates to start at the 6th character.
            </p>

            <p>
              If{' '}
              <em>
                <strong>position</strong>
              </em>{' '}
              is greater than the length of text, no text is returned.
            </p>

            <p>
              If{' '}
              <em>
                <strong>position</strong>
              </em>{' '}
              is less than the length of text, but{' '}
              <em>
                <strong>position</strong>
              </em>{' '}
              plus{' '}
              <em>
                <strong>num_chars</strong>
              </em>{' '}
              exceeds the length of{' '}
              <em>
                <strong>text</strong>
              </em>
              , the characters up to the end of the text are returned.
            </p>
          </>
        ),
      },
      {
        name: 'num_chars',
        description: <>Required. Specifies the number of characters you want to extract.</>,
      },
    ],
    example: {
      title: 'Examples',
      formulaList: ['MID([Account Description],6,5)', 'MID([Account Description],1,5)'],
    },
  },
  SEARCH: {
    placeholderParams: ['find_text', 'within_text', '[start_pos]'],
    description: (
      <>
        Returns the position of text within other text. Returns -1 if the desired text does not exist. The function is
        case-insensitive.
      </>
    ),
    parameters: [
      {
        name: 'find_text',
        description: <>Required. The text that you want to find.</>,
      },
      {
        name: 'within_text',
        description: (
          <>
            Required. The text or [field name] in which you want to search for the{' '}
            <em>
              <strong>find_text</strong>
            </em>{' '}
            value.
          </>
        ),
      },
      {
        name: '[start_pos]',
        description: (
          <>
            Optional. The character number in the{' '}
            <em>
              <strong>within_text</strong>
            </em>{' '}
            argument at which you want to start searching.
          </>
        ),
      },
    ],
    example: {
      title: 'Example',
      formulaList: ['SEARCH("Cash",[Account Description],1)'],
    },
  },
  CONCATENATE: {
    placeholderParams: ['text1', 'text2', '[text...]'],
    description: <>Combines multiple text values.</>,
    parameters: [
      {
        name: 'text1',
        description: <>Required. The text or [field name] that you want to combine.</>,
      },
      {
        name: 'text2',
        description: <>Required. The text or [field name] that you want to combine.</>,
      },
      {
        name: '[text...]',
        description: <>Optional. Additional text values that you want to combine.</>,
      },
    ],
    example: {
      title: 'Examples',
      formulaList: ['CONCATENATE([Account],[Description])', 'CONCATENATE([Account], " - ", [Description])'],
    },
  },
  UPPER: {
    placeholderParams: ['text'],
    description: <>Converts text to uppercase.</>,
    parameters: [
      {
        name: 'text',
        description: <>Required. The text or [field name] that you want to convert to uppercase.</>,
      },
    ],
    example: {
      title: 'Example',
      formulaList: ['UPPER([Account Description])'],
    },
  },
  LOWER: {
    placeholderParams: ['text'],
    description: <>Converts text to lowercase.</>,
    parameters: [
      {
        name: 'text',
        description: <>Required. The text or [field name] that you want to convert to lowercase.</>,
      },
    ],
    example: {
      title: 'Example',
      formulaList: ['LOWER([Account Description])'],
    },
  },
  CONTAINS: {
    placeholderParams: ['find_text', 'within_text', '[case_insensitive]'],
    description: <>Returns true or false depending on whether text is found within other text.</>,
    parameters: [
      {
        name: 'find_text',
        description: <>Required. The text that you want to find.</>,
      },
      {
        name: 'within_text',
        description: (
          <>
            Required. The text or [field name] in which you want to search for the{' '}
            <em>
              <strong>find_text</strong>
            </em>{' '}
            value.
          </>
        ),
      },
      {
        name: '[case_insensitive]',
        description: (
          <>
            Optional. Case senstive by default.
            <br />
            If true is entered, then{' '}
            <em>
              <strong>find_text</strong>
            </em>{' '}
            is case insensitive.
          </>
        ),
      },
    ],
    example: {
      title: 'Examples (Advanced Filter)',
      formulaList: ['CONTAINS("total",[Account Description])', 'CONTAINS("total",[Account Description],true)'],
    },
  },
  IF: {
    placeholderParams: ['condition', 'value_if_true', 'value_if_false'],
    description: <>Performs a comparison that results in either a true or false value.</>,
    parameters: [
      {
        name: 'condition',
        description: <>Required. The condition you want to evaluate.</>,
      },
      {
        name: 'value_if_true',
        description: <>Required. The value you want to return if the condition is true.</>,
      },
      {
        name: 'value_if_false',
        description: <>Required. The value you want to return if the condition is false.</>,
      },
    ],
    example: {
      title: 'Examples',
      formulaList: ['IF([Amount]>50000,"Material","")', 'IF(CONTAINS("Total",[Account Description]),"",[Amount])'],
    },
  },
  IFS: {
    placeholderParams: ['condition1', 'value_if_true1', '[condition2]', '[value_if_true2]', '...'],
    description: (
      <>
        Evaluates whether one or more conditions are met and returns a value that corresponds to the first true
        condition. If none of the conditions are met, 0 is returned.
      </>
    ),
    parameters: [
      {
        name: 'condition1',
        description: <>Required. The first condition you want to evaluate.</>,
      },
      {
        name: 'value_if_true1',
        description: <>Required. The value you want to return if the first condition is true.</>,
      },
      {
        name: '[condition2]',
        description: <>Optional. The second condition you want to evaluate.</>,
      },
      {
        name: '[value_if_true_2]',
        description: <>Optional. The value you want to return if the second condition is true.</>,
      },
    ],
    example: {
      title: 'Examples',
      formulaList: ['IFS([Amount]<15000,"Low",[Amount]>50000,"High")'],
    },
  },
  AND: {
    placeholderParams: ['condition1', 'condition2', '...'],
    description: (
      <>Evaluates whether all conditions are true, returning true if the conditions are all true and false otherwise.</>
    ),
    parameters: [
      {
        name: 'condition1',
        description: <>Required. The first condition you want to evaluate.</>,
      },
      {
        name: 'condition2',
        description: <>Required. The second condition you want to evaluate.</>,
      },
    ],
    example: {
      title: 'Example (Advanced Filter)',
      formulaList: ['AND([Account Description]<>"Total",""<>[Amount])'],
    },
  },
  OR: {
    placeholderParams: ['condition1', 'condition2', '...'],
    description: (
      <>
        Evaluates multiple conditions, returning true if any conditions are true; otherwise, returns false if all
        arguments are false.
      </>
    ),
    parameters: [
      {
        name: 'condition1',
        description: <>Required. The first condition you want to evaluate.</>,
      },
      {
        name: 'condition2',
        description: <>Required. The second condition you want to evaluate.</>,
      },
    ],
    example: {
      title: 'Example (Advanced Filter)',
      formulaList: ['OR([Account Description]="Total",""=[Amount])'],
    },
  },
  NOT: {
    placeholderParams: ['condition'],
    description: <>Negates the provided condition.</>,
    parameters: [
      {
        name: 'condition',
        description: <>Required. The condition you want to negate.</>,
      },
    ],
    example: {
      title: 'Example (Advanced Filter)',
      formulaList: ['NOT(OR([Amount]="",CONTAINS("Total",[Account Description])))'],
    },
  },
  ISERROR: {
    placeholderParams: ['value'],
    description: (
      <>
        Evaluates whether a value contains an error, returning true if the value contains an error and false otherwise.
      </>
    ),
    fullDescription: (
      <>
        <p>There are a few use cases that result in a value showing as #VALUE! in the preview pane:</p>
        <ol>
          <li>The data type for the column is number, but the value is not a number.</li>
          <li>The data type for the column is date, but the value is not in a recognized date format.</li>
          <li>Division by 0.</li>
        </ol>
        <p>
          To identify errors in your data, you can use the <strong>ISERROR</strong> function using the{' '}
          <strong>Filter block</strong>
        </p>
        .
      </>
    ),
    parameters: [
      {
        name: 'value',
        description: <>Required. The value or [field name] that is checked for errors.</>,
      },
    ],
    example: {
      title: 'Example (Advanced Filter)',
      formulaList: ['ISERROR([Amount])'],
    },
  },
  ROUND: {
    placeholderParams: ['value'],
    description: <>Returns a number rounded based on the number of digits you specify.</>,
    parameters: [
      {
        name: 'number',
        description: <>Required. The number or [column name] containing the numbers you want to round.</>,
      },
      {
        name: 'num_digits',
        description: <>Required. Specifies the number of digits you want to round to.</>,
      },
    ],
    example: {
      title: 'Examples',
      formulaList: ['ROUND([RATE],2)'],
    },
  },
  SUBSTITUTE: {
    placeholderParams: ['value'],
    description: <>Substitutes new_text for old_text.</>,
    parameters: [
      {
        name: 'text',
        description: (
          <>Required. The text or [column name] containing the old_text you want to substitute with new_text.</>
        ),
      },
      {
        name: 'old_text',
        description: <>Required. The text you want to substitute.</>,
      },
      {
        name: 'new_text',
        description: <>Required. The text you want to substitute.</>,
      },
      {
        name: '[instance_num]',
        description: (
          <>
            Optional. Specifies which occurrence of old_text you want to substitute with new_text. If you specify
            instance_num, only that instance of old_text is substituted. Otherwise, every occurrence of old_text in text
            is changed to new_text.
          </>
        ),
      },
    ],
    example: {
      title: 'Example',
      formulaList: ['SUBSTITUTE([Entity],"Incorporated", "Inc.", 1)'],
    },
  },
};

export const functions = {
  ...commonFunctions,
  TRIM: {
    placeholderParams: ['text', '[trim_char]'],
    description: (
      <>
        This function removes leading and trailing instances of trim_char from text. If trim_char is not specified,
        spaces are trimmed.
      </>
    ),
    parameters: [
      {
        name: 'text',
        description: (
          <>Required. The text or [field name] from which you want spaces or specified characters removed.</>
        ),
      },
      {
        name: '[trim_char]',
        description: (
          <>
            <p>Optional. Specify certain characters within quotation marks that you want to be trimmed.</p>

            <p>
              If{' '}
              <em>
                <strong>[trim_char]</strong>
              </em>{' '}
              is not specified, spaces are trimmed from text except for single spaces between words.
            </p>
          </>
        ),
      },
    ],
    example: {
      title: 'Examples',
      formulaList: ['TRIM([Account Description],"Total")', 'TRIM([Account Description],"Total Cash")'],
    },
  },
};

export const filterFunctions = { ...commonFunctions };

const functionTriggerArray = [
  ...new Set(
    Object.keys(functions)
      .map(k => k.substring(0, 1))
      .sort()
  ),
];

export const triggerStrings = {
  byFunc: functionTriggerArray, // functions dropdown will be triggered by first letter of supported function Names
  byFields: '[', // Displays block input fields
  byHint: '(', // Displays function hint windows
  byFuncStr: '=', // for validation purposes
};

const supportedFunctions = Object.keys(functions).sort();

const supportedFilterFunctions = Object.keys(filterFunctions).sort();

export const functionOptions = functionTriggerArray.reduce((prev, acc) => {
  const functionsByKey = supportedFunctions.filter(f => f.substring(0, 1) === acc);
  acc = { ...prev, [acc]: functionsByKey };
  return acc;
}, {});

export const functionNamesWithVariableParameterCount = Object.keys(functions)
  .map(functionName => {
    return {
      ...functions[functionName],
      functionName: functionName,
    };
  })
  .filter(functionData => {
    const placeholderParams = functionData.placeholderParams;
    return placeholderParams[placeholderParams.length - 1] === '...';
  })
  .reduce((functionMap, functionData) => {
    functionMap[functionData.functionName] = functionData.placeholderParams.length - 1;
    return functionMap;
  }, new Map());

export const filterFunctionOptions = functionTriggerArray.reduce((prev, acc) => {
  const functionsByKey = supportedFilterFunctions.filter(f => f.substring(0, 1) === acc);
  acc = { ...prev, [acc]: functionsByKey };
  return acc;
}, {});

const { byHint, byFields } = triggerStrings;

export const hintOptions = { [byHint]: ['__'] };
export const fieldOptions = fields => ({ [byFields]: fields });
