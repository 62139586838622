import React, { createContext, useContext, useMemo } from 'react';
import { useClientSync } from '../hooks/useClientSync';
import { isFeatureFlagEnabled } from '../utils/featureFlags';
import { WKP_CM_INTEGRATION } from '../constants/featureFlags';

const ClientContext = createContext();

function ClientProvider({ children }) {
  const { clientId, setClientId } = useClientSync();

  const value = useMemo(
    () => ({
      clientId,
      setClientId,
    }),
    [clientId, setClientId]
  );

  return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;
}

export function ClientProviderProxy({ children }) {
  if (!isFeatureFlagEnabled(WKP_CM_INTEGRATION)) {
    return children;
  }

  return <ClientProvider>{children}</ClientProvider>;
}

export function useClient() {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error('ClientProvider is not defined');
  }
  return context;
}
