import { eventTrackTagNames } from '../../../../_shared/EventTrackingContext/constants';
import dataFlowConnectionSvg from './data_flow_connections_icon.svg';

const sharedFontAwesomeClasses = 'fas fa-lg fa-fw';

export const sideBarPanelItems = [
  {
    id: 'wkp-source-btn',
    title: 'Data Connect Details',
    label: 'Data Connect Details',
    name: 'sourceData',
    children: (
      <img
        alt="Data Connect Details"
        data-track-tag={eventTrackTagNames.interactive}
        // Added id for data tracking
        id="wkp-source-btn"
        src={dataFlowConnectionSvg}
      />
    ),
  },
  {
    id: 'wkp-tax-law-research-btn',
    title: 'Tax law research',
    label: 'Tax law research',
    name: 'taxLawResearch',
    children: <i className={`${sharedFontAwesomeClasses} fa-gavel`} />,
  },
  {
    id: 'wkp-workflow-btn',
    title: 'Workflow',
    label: 'Workflow',
    name: 'workflow',
    children: <i className={`${sharedFontAwesomeClasses} fa-tasks`} />,
  },
  {
    id: 'wkp-settings-btn',
    title: 'Workpaper settings',
    label: 'Settings',
    name: 'settings',
    children: <i className={`${sharedFontAwesomeClasses} fa-cog`} />,
  },
  {
    id: 'wkp-history-btn',
    title: 'Workpaper history',
    label: 'History',
    name: 'history',
    children: (
      <i
        className={`${sharedFontAwesomeClasses} fa-history`}
        // Added id for data tracking
        data-track-tag={eventTrackTagNames.interactive}
        id="wkp-history-btn"
      />
    ),
  },
];
