import React, { useEffect, useState } from 'react';
import ConnectionPanel from './SideBar/ConnectionPanel';
import HistoryPanel from './SideBar/HistoryPanel';
import TaxLawGuidancePanel from './SideBar/TaxLawGuidancePanel';
import SettingsPanel from './SideBar/SettingsPanel';
import WorkflowPanel from './SideBar/WorkflowPanel';
import SideBarPanelItem from './SideBar/SideBarPanelItem';
import './SideBar/styles.scss';
import { sideBarPanelItems } from './SideBar/sideBarItems';
import { useKeyData } from './_spreadsheets/useKeyData';

export default function SideBar({
  setOpenedPanel,
  openedPanel,
  inHistoryViewMode,
  workpaperId,
  setReadOnlyModeDisplayName,
  isLocked,
}) {
  const [top, setTop] = useState(0);
  const { getAllKeyValues } = useKeyData({ workpaperId });

  useEffect(() => {
    const elementClass = isLocked ? 'editor' : 'ribbon-panel';
    const elementToMatch = document.getElementsByClassName(elementClass)[0];
    if (elementToMatch) {
      const { top } = elementToMatch.getBoundingClientRect();
      setTop(top);
    }
  }, [isLocked]);

  useEffect(() => {
    if (inHistoryViewMode) {
      setOpenedPanel('history');
    }
    const openSettingsPanelIfNecessary = async () => {
      const wkpOpenedPreviously = 'wkpOpenedPreviously';
      const keyValuePairs = await getAllKeyValues();
      const wasOpenedBefore = keyValuePairs.find(pair => pair.key === wkpOpenedPreviously)?.value;
      if (wasOpenedBefore !== 'true') {
        setOpenedPanel('settings');
      }
    };
    openSettingsPanelIfNecessary();
    // createKeyValue and getAllKeyValues will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inHistoryViewMode, workpaperId]);

  const handleOnPanelClose = () => {
    setOpenedPanel(null);
  };

  const panels = {
    sourceData: <ConnectionPanel workpaperId={workpaperId} onClose={handleOnPanelClose} />,
    history: (
      <HistoryPanel
        setReadOnlyModeDisplayName={setReadOnlyModeDisplayName}
        workpaperId={workpaperId}
        onClose={handleOnPanelClose}
      />
    ),
    taxLawResearch: <TaxLawGuidancePanel workpaperId={workpaperId} onClose={handleOnPanelClose} />,
    settings: <SettingsPanel workpaperId={workpaperId} onClose={handleOnPanelClose} />,
    workflow: <WorkflowPanel workpaperId={workpaperId} onClose={handleOnPanelClose} />,
  };

  const handleSideBarIconClick = panel => () => {
    setOpenedPanel(prev => (prev === panel ? null : panel));
  };

  return (
    <div id="wkp-side-bar" style={{ top }}>
      <div className="wkp-right-nav">
        <nav>
          <ul className="nav">
            {sideBarPanelItems.map(({ id, title, label, name, children }) => (
              <SideBarPanelItem
                key={id}
                buttonId={id}
                buttonLabel={label}
                buttonTitle={title}
                isActive={openedPanel === name}
                name={name}
                onSideBarIconClick={handleSideBarIconClick}
              >
                {children}
              </SideBarPanelItem>
            ))}
          </ul>
        </nav>
      </div>
      {openedPanel && panels[openedPanel]}
    </div>
  );
}
