/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { BTIcon } from '@btas/jasper';
import { ecdsCpocToolUrl, clientAccessManagerUrl } from '../../../../configs/params';
import { roles, hasUserRole } from '../../../../utils/userRoles';

import './styles.scss';

const CmAdminMenu = () => {
  const isUserCpocAdmin = hasUserRole(roles.CPOC_ADMIN);

  if (!isUserCpocAdmin) {
    return null;
  }

  return (
    <>
      <li className="dropdown admin-menu">
        <a
          className="dropdown-toggle"
          data-testid="admin-menu-dropdown"
          data-toggle="dropdown"
          href="#"
          id="header-admin-menu-button"
          title="Admin Menu"
        >
          <BTIcon icon="gear" />
          <span className="visible-xs-inline">Admin Menu</span>
        </a>
        <ul aria-labelledby="header-admin-menu-button" className="dropdown-menu" role="menu">
          <li aria-level="2" className="dropdown-header settings" role="heading">
            SETTINGS
          </li>

          <li data-testid="admin-menu-users-button" role="presentation">
            <a href={ecdsCpocToolUrl} id="admin-menu-users" rel="noreferrer" role="menuitem" target="_blank">
              Users
            </a>
          </li>
          <li data-testid="admin-menu-client-access-manager-button" role="presentation">
            <a
              href={clientAccessManagerUrl}
              id="admin-menu-client-access-manager"
              rel="noreferrer"
              role="menuitem"
              target="_blank"
            >
              Client Access Manager
            </a>
          </li>
        </ul>
      </li>
    </>
  );
};

export default CmAdminMenu;
