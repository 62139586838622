import React, { useState } from 'react';
import FakeBanner from './Frame/FakeBanner';
import FeedbackForm from './Frame/FeedbackForm';
import Footer from './Frame/Footer';
import AppBar from './Frame/AppBar';
import { BTAlert } from '@btas/jasper';
import './Frame/styles.scss';
import CmFakeBanner from './Frame/CmFakeBanner/CmFakeBanner';
import { isFeatureFlagEnabled } from '../../utils/featureFlags';
import { WKP_CM_INTEGRATION } from '../../constants/featureFlags';

export default function Frame({ children, activeItem, className, showAppBar = true, isDataFlowDirty }) {
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const [message, setMessage] = useState();
  const [messageType, setMessageType] = useState('success');

  const onModalClose = () => {
    setShowFeedbackForm(false);
  };

  const onModalOpen = () => {
    setShowFeedbackForm(true);
    // on open set focus to textarea, timeout must be present so that element has rendered before query
    setTimeout(() => {
      document.querySelector('textarea.bbui-text-area')?.focus();
    });
  };

  /* eslint-disable no-unused-vars */
  const handleErrorMessage = message => {
    setMessageType('error');
    setMessage(message);
  };

  const handleDismissAlert = () => {
    setMessage(null);
  };

  const handleSuccessMessage = message => {
    setMessageType('success');
    setMessage(message);
    // automatically dismiss success message after 5 seconds
    setTimeout(() => {
      handleDismissAlert();
    }, 5000);
    onModalClose();
  };

  return (
    <div id="wkp-page-container">
      {isFeatureFlagEnabled(WKP_CM_INTEGRATION) ? (
        <CmFakeBanner isDataFlowDirty={isDataFlowDirty} onOpenFeedbackFormClick={onModalOpen} />
      ) : (
        <FakeBanner onOpenFeedbackFormClick={onModalOpen} />
      )}
      <BTAlert appear dismissible fixed btStyle={messageType} visible={!!message} onDismiss={handleDismissAlert}>
        {message}
      </BTAlert>
      {showAppBar ? <AppBar activeItem={activeItem} /> : null}
      <main className={className} role="main">
        {children}
      </main>
      <Footer />
      {showFeedbackForm && <FeedbackForm onClose={onModalClose} onSuccess={handleSuccessMessage} />}
    </div>
  );
}
