import { setCountCommand } from '../../useWorkpaper/processCommand';
import { useKeyData } from './useKeyData';
import { DEFAULT_SHEET_ROW_COUNT, DEFAULT_SHEET_COL_COUNT } from '../../../../../configs/params';
import { getDataFromLocalStorage, setDataToLocalStorage } from '../../../../_shared/storage';

export default function useResize({ id }) {
  const resizedKey = `resized`;
  const { getAllKeyValues, createKeyValue } = useKeyData({ workpaperId: id });

  async function setResized(spread) {
    if (spread) {
      const sheetName = spread.getActiveSheet().name();
      const keyValuePairs = await getKeyValuePairs(sheetName, resizedKey);
      if (!keyValuePairs[resizedKey]) {
        const callback = async () => {
          await createKeyValue({
            workpaperId: id,
            keyData: { key: resizedKey, value: 'true', sheetName: sheetName, forceSave: true },
          });
        };
        const { copyWorksheet, currentSheets } = JSON.parse(getDataFromLocalStorage(id) || '{}');
        if (!copyWorksheet) {
          await setCountCommand(spread, DEFAULT_SHEET_ROW_COUNT, DEFAULT_SHEET_COL_COUNT, callback);
        } else {
          if (currentSheets.length) {
            const latestCurrentSheets = spread.sheets.map(x => x.name());
            const worksheetsToCopy = latestCurrentSheets.filter(function (sheet) {
              return currentSheets.indexOf(sheet) === -1;
            });
            if (worksheetsToCopy.length) {
              worksheetsToCopy.forEach(async sheet => {
                await createKeyValue({
                  workpaperId: id,
                  keyData: { key: resizedKey, value: 'true', sheetName: sheet, forceSave: true },
                });
              });
              const workpaperData = JSON.parse(getDataFromLocalStorage(id) || '{}');
              workpaperData['currentSheets'] = [];
              setDataToLocalStorage(id, JSON.stringify(workpaperData));
            }
          }
        }
      }
    }
  }

  async function getKeyValuePairs(sheetName, ...keys) {
    const keyData = await getAllKeyValues();
    const keyValuePairs = keyData.reduce((kvPairs, kv) => {
      if (keys.includes(kv.key) && kv.sheetName === sheetName) kvPairs[kv.key] = kv.value;
      return kvPairs;
    }, {});

    return keyValuePairs;
  }

  return { setResized };
}
