import { BTButton, BTModal } from '@btas/jasper';
import React from 'react';

const DataFlowSaveChangesModal = ({ showConfirm, onCloseConfirm, onConfirm }) => {
  return (
    <BTModal show={showConfirm} title="Close Data Connect Workflow" onCloseClick={onCloseConfirm}>
      <BTModal.Body>The Data Connect workflow has unsaved changes. What would you like to do?</BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onConfirm}>Discard changes and close</BTButton>
        <BTButton btStyle="primary" onClick={onCloseConfirm}>
          Cancel
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};

export default DataFlowSaveChangesModal;
