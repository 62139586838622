import React, { useRef, useEffect, useState, useCallback, useContext } from 'react';
import { useGraph } from './DataFlowCanvas/useGraph';
import { useDataFlowEventHandlers } from './DataFlowCanvas/useDataFlowEventHandlers';
import { usePaper } from './DataFlowCanvas/usePaper';
import { useLinkHalo } from './DataFlowCanvas/useLinkHalo';
import { useElementTools } from './DataFlowCanvas/useElementTools';
import { useRestoreCanvas } from './DataFlowCanvas/useRestoreCanvas';
import { restoreElements } from './DataFlowCanvas/restoreElementsHandler';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import { useTooltips } from './DataFlowCanvas/useTooltips';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_DATAFLOW_SIDEBAR } from '../../../constants/featureFlags';
import { ui } from '@clientio/rappid';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';
import './DataFlowCanvas/styles.scss';

const { v4: uuidv4 } = require('uuid');

export const DataFlowCanvas = ({ initialDataFlow, inspectorIsPresent, previewIsPresent, configInspectorIsPresent }) => {
  const canvasContainer = useRef(null);
  const [canvasClass, setCanvasClass] = useState('wkp-data-flow-canvas');
  const { dataFlowState, dataFlowActions, showConfirmationModal } = useContext(DataFlowEditorContext);
  const { graph } = dataFlowState;
  const [blockSelection, setBlockSelection] = useState();
  const keyboard = useRef(new ui.Keyboard());
  const clipboardBlocks = useRef([]);
  const canEditWorkflow = useCanEditWorkflow();

  // Initialize graph
  useGraph();

  // Initialize paper and paper scroller
  usePaper(canvasContainer, setBlockSelection);

  // Enable event handlers for Data Flow Editor
  useDataFlowEventHandlers(blockSelection);

  // Enable Halo component for active links
  useLinkHalo();

  // Enable element Tools component for active elements
  useElementTools();

  useRestoreCanvas(initialDataFlow.configuration);

  useTooltips();

  useEffect(() => {
    const inspectorClassToUse = isFeatureFlagEnabled(WKP_DATAFLOW_SIDEBAR)
      ? 'wkp-data-flow-canvas-no-inspector-sidebar'
      : 'wkp-data-flow-canvas-no-inspector';
    const inspectorClass =
      inspectorIsPresent || configInspectorIsPresent ? 'wkp-data-flow-canvas-inspector' : inspectorClassToUse;
    const previewClass = previewIsPresent ? 'wkp-data-flow-canvas-preview' : 'wkp-data-flow-canvas-no-preview';

    const newCanvasClass = `wkp-data-flow-canvas ${inspectorClass} ${previewClass}`;

    setCanvasClass(newCanvasClass);
  }, [inspectorIsPresent, previewIsPresent, configInspectorIsPresent]);

  // Handle Ctrl+C
  const handleCtrlC = useCallback(() => {
    if (canEditWorkflow) {
      clipboardBlocks.current = blockSelection.collection.map(model => ({
        id: model.id,
        position: model.attributes.position,
        size: model.attributes.size,
        text: model.attributes.attrs?.label?.text,
      }));
    }
  }, [blockSelection, canEditWorkflow]);

  const handleCtrlV = useCallback(() => {
    if (canEditWorkflow === false || clipboardBlocks.current?.length === 0) {
      return;
    }

    const names = [];

    const paste = clipboardBlocks.current.reduce(
      (acum, block) => {
        const original = dataFlowState?.elements[block.id];
        if (!original || original.elementData.type === 'input' || original.elementData.type === 'output') {
          return acum;
        }

        const newId = uuidv4();
        const newName = createNameForCopy(
          original.elementData?.name,
          original.elementData?.type,
          dataFlowState.elements,
          names
        );

        names.push(newName);

        acum.elements[newId] = {
          ...original.elementData,
          id: newId,
          name: newName,
          bounds: {
            width: block?.size?.width,
            height: block?.size?.height,
            left: block?.position?.x + 100,
            top: block?.position?.y + 100,
          },
        };

        if (dataFlowState.notes && dataFlowState.notes[block.id]) {
          acum.notes[newId] = dataFlowState.notes[block.id];
        }

        if (original.elementData?.type === 'annotation') {
          acum.elements[newId].text = block.text;
        }

        return acum;
      },
      {
        elements: {},
        notes: {},
      }
    );

    clipboardBlocks.current = [];

    restoreElements(paste.elements, graph, dataFlowActions);
    dataFlowActions.setElementsNotes(paste.notes);

    dataFlowActions.syncElementState();
  }, [dataFlowState, dataFlowActions, graph, canEditWorkflow]);

  const handleBackspace = useCallback(() => {
    if (canEditWorkflow && !dataFlowState.activeElement) {
      const blocks = blockSelection.collection.filter(
        model => model?.attributes?.type !== 'input' && model?.attributes?.type !== 'output'
      );

      const removeIds = blocks.map(model => model.id);
      const count = blocks.length;

      if (count > 0) {
        showConfirmationModal(
          'Delete Blocks',
          `Are you sure you would like to delete the ${count} selected block${count > 1 ? 's' : ''}? This cannot be undone.`,
          'Delete',
          'Cancel',
          'warning',
          () => {
            dataFlowActions.removeElements(removeIds);

            blocks.forEach(block => block.remove());
          }
        );
      }
    }
  }, [blockSelection, dataFlowState.activeElement, dataFlowActions, canEditWorkflow, showConfirmationModal]);

  useEffect(() => {
    const { paper } = dataFlowState;

    if (paper && keyboard) {
      const keyboardInstance = keyboard?.current;

      keyboardInstance.on({
        'ctrl+c': handleCtrlC,
        'ctrl+v': handleCtrlV,
        'keyup:delete': handleBackspace,
        'keyup:backspace': handleBackspace,
      });

      const handleKeyDown = evt => {
        if (paper.el === evt.target || paper.el.contains(evt.target)) {
          keyboardInstance.onKeyDown(evt);
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        keyboardInstance.off('ctrl+c ctrl+v keyup:delete keyup:backspace');
      };
    }
  }, [dataFlowState, handleCtrlC, handleCtrlV, handleBackspace]);

  return <div ref={canvasContainer} className={canvasClass} />;
};

const regexp = /(\d+)$/;

export function createNameForCopy(originalName, type, elements, names = []) {
  if (type === 'annotation') {
    return originalName;
  }

  const usedNames = new Set(names);
  let suffix = 1;
  let name = originalName;

  for (const id in elements) {
    usedNames.add(elements[id].elementData.name);
  }

  while (usedNames.has(name)) {
    if (regexp.test(name.trim())) {
      name = name.trim().replace(regexp, suffix.toString());
    } else {
      name = `${originalName} ${suffix}`;
    }

    suffix++;
  }

  return name;
}
