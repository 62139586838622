import * as api from '../../../../_shared/fetch';
import { spreadsheetServiceHostName, workpapersSpreadsheetModelHostName } from '../../../../../configs/params';

export async function copySheet({ sourceWorkpaperId, worksheetNames, targetWorkpaperId, beforeSheet, jobId }) {
  const response = await api.post(`${workpapersSpreadsheetModelHostName}/file/copy-worksheets/${targetWorkpaperId}`, {
    body: JSON.stringify({
      sourceWorkbookId: sourceWorkpaperId,
      worksheetNames,
      beforeSheet,
      jobId,
    }),
  });

  const result = { success: response.ok };

  if (!response.ok) {
    if (response.status === 400 || response.status === 409) {
      const data = await response.json();
      result.errorMessage = data.title;
    } else {
      result.systemErrorMessage = await response.text();
    }
  }

  return result;
}

export async function getWorkpapers() {
  const response = await api.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers`);
  return await response.json();
}

export async function getWorkbookSheets(workpaperId) {
  const response = await api.get(`${workpapersSpreadsheetModelHostName}/file/worksheet-names/${workpaperId}`);

  return await response.json();
}
