import { env } from '../env';
const NodeJsAPIPath = env.REACT_APP_API_NODE_PATH || 'http://localhost:13200/workpapers-node';
const DotNetCoreAPIPath = env.REACT_APP_API_PATH || 'http://localhost:13100';
const SpreadsheetModelAPIPath = env.REACT_APP_API_SPREADSHEET_MODEL_PATH || 'http://localhost:13600';
const DataflowAPIPath = env.REACT_APP_API_DATA_FLOW_PATH || 'http://localhost:13400/workpapers-dataflow';
const NodeAPIPath = env.REACT_APP_NODE_API_GATEWAY_URL || env.REACT_APP_WKP_API_GATEWAY_URL;

export const workpapersServiceHostName = DotNetCoreAPIPath;
export const workpapersNodeServiceHostName = NodeJsAPIPath;
export const workpapersSpreadsheetModelHostName = SpreadsheetModelAPIPath;
export const spreadsheetsServiceHostName = DotNetCoreAPIPath;
export const uploadServiceHostName = DotNetCoreAPIPath;
export const loginServiceHostName = NodeJsAPIPath;
export const historyServiceHostName = DotNetCoreAPIPath;
export const bnaPicksServiceHostName = NodeJsAPIPath;
export const taxRatesServiceUrl = `${
  env.REACT_APP_LOCAL_TAX_RATES_URL || env.REACT_APP_WKP_API_GATEWAY_URL
}/tax/tax-rates`;
export const taxLawGuidanceServiceHostName = NodeJsAPIPath;
export const usersServiceHostName = DataflowAPIPath;
export const dataFlowServiceHostName = DataflowAPIPath;
export const workpapersDomainName = env.REACT_APP_WKP_API_GATEWAY_URL;
export const nodeAPIServiceHostName = NodeAPIPath;

export const ecdsLoginPageUrl = env.REACT_APP_ECDS_LOGIN_PAGE_URL;
export const ecdsLogoutPageUrl = env.REACT_APP_ECDS_LOGOUT_PAGE_URL;
export const AUTHENTICATOR_ENDPOINT = `${env.REACT_APP_WKP_API_GATEWAY_URL}/token`;
export const PROVISION_ENDPOINT = `${env.REACT_APP_WKP_API_GATEWAY_URL}/system/state`;
export const workflowAutomationUrl = env.REACT_APP_WORKFLOW_AUTOMATION_URL;
export const consoleLogging = env.REACT_APP_CONSOLE_LOGGING;
export const faLearnMoreLink = 'https://pro.bloombergtax.com/fixed-assets/';
export const fixedAssetsLoginPageUrl =
  env.REACT_APP_FIXED_ASSETS_LOGIN_PAGE_URL || 'https://advantage.bloombergtaxtech.com/';

export const wijmoKey = env.REACT_APP_WIJMO_KEY || env.CICD_WIJMO_KEY;
export const spreadjsKey = env.REACT_APP_SPREADJS_KEY || env.CICD_SPREADJS_KEY;
export const spreadjsDesignerKey = env.REACT_APP_SPREADJS_DESIGNER_KEY || env.CICD_SPREADJS_DESIGNER_KEY;

export const featureFlagsServiceHostName = env.REACT_APP_WKP_API_GATEWAY_URL;
export const spreadsheetServiceHostName = env.REACT_APP_WORKBOOK_COMMANDS || env.REACT_APP_WKP_API_GATEWAY_URL;
export const maxCommandQueueRetries = env.REACT_APP_COMMAND_QUEUE_MAX_RETRIES || 5;
export const maxCommandFailsRetries = env.REACT_APP_COMMAND_FAILS_MAX_RETRIES || 2;
export const commandCheckStatusThreshold = env.REACT_APP_COMMAND_SAVE_TIMEOUT || 1_000;
export const dataReferenceUpdateTimeout = env.REACT_APP_DATA_REFERENCE_UPDATE_TIMEOUT || 1000;
export const cleanUpJobsTimeoutInSeconds = env.REACT_APP_CLEANUP_JOBS_TIMEOUT_IN_SECONDS || 330;
export const numberOfTokenRefreshesToRedirectOnLogin =
  env.REACT_APP_NUMBER_OF_TOKEN_REFRESHES_TO_REDIRECT_ON_LOGIN || 24;
export const tokenExpirationTimeout = 240000; // 4 min for token expiration timeout
export const maxCommandDataSizeInMB = env.REACT_APP_MAX_COMMAND_DATA_SIZE_IN_MB || 3;
export const tooltipTogglerCacheTimeout = 5 * 60 * 1000; // 5 minutes
export const launchDarklyClientID = env.REACT_APP_API_LD_CLIENT_ID;
export const EnviromentName = env.REACT_APP_NODE_ENV;
export const HardDelete = env.REACT_APP_NODE_HARD_DELETE;
export const EnviromentUrl = env.REACT_APP_ENVIROMENT_URL;
export const globalAccountId = env.REACT_APP_GLOBAL_ACCOUNT_ID || 'test-global-template-id';

export const MAX_ALLOWED_CONCURRENT_DOWNLOADS = Number(env.REACT_APP_MAX_ALLOWED_CONCURRENT_DOWNLOADS) || 15;
export const DEFAULT_SHEET_ROW_COUNT = 200;
export const DEFAULT_SHEET_COL_COUNT = 26;
export const workpapersReleaseVersion = env.REACT_APP_WORKPAPERS_RELEASE_VERSION;
export const userDataAPIPath = env.REACT_APP_API_USER_DATA_PATH || 'http://localhost:14000';
export const taxProvisionURL = env.REACT_APP_TAX_PROVISION_URL;
export const taxProvisionClassicURL = env.REACT_APP_TAX_PROVISION_CLASSIC_URL;
export const coreAPIHostName = env.REACT_APP_CORE_API_GATEWAY_URL || env.REACT_APP_WKP_API_GATEWAY_URL;
export const analyticsEnvironment = env.REACT_APP_ANALYTICS_ENVIRONMENT;
export const analyticApiKey = env.REACT_APP_ANALYTICS_API_KEY;
export const skipEventsTracking = env.REACT_APP_SKIP_EVENTS_TRACKING;

export const clientAccessManagerUrl = env.REACT_APP_CLIENT_ACCESS_MANAGER_URL;
export const ecdsCpocToolUrl = env.REACT_APP_ECDS_CPOC_TOOL_URL;
