import { tooltipTogglerCacheTimeout } from '../../../../../configs/params';
import { STATE_APPORTION } from '../../../../_shared/DataReference/ReferenceType';
import { getApportionmentData } from '../_spreadsheets/apis';
import { TooltipToggleHelper } from './tooltipToggleHelper';

const cache = {};

function getCacheKey(args) {
  return JSON.stringify(args);
}

export class ApportionRateTooltipHelper extends TooltipToggleHelper {
  constructor(fnArguments) {
    super();
    this.args = fnArguments;
    this.state = fnArguments[0];
    this.date = fnArguments[1];
    this.apportionType = fnArguments[2];
    this.taxRateData = null;
    this.type = STATE_APPORTION;
  }

  buildTooltipMessage() {
    const { summary, citation } = this.taxRateData;

    let message;
    message = summary?.replace(citation, '').trim();

    return message;
  }

  async _fetchData() {
    this.taxRateData = await getApportionmentData({
      state: this.state,
      date: this.date,
      type: this.apportionType,
    });

    return {
      tooltipContent: this.buildTooltipMessage(),
      link: this.taxRateData.source,
    };
  }

  async fetchData() {
    const cacheKey = getCacheKey(this.args);
    const cachedEntry = cache[cacheKey];

    if (cachedEntry && Date.now() - cachedEntry.timestamp < tooltipTogglerCacheTimeout) {
      console.log('Returning cached data for apportion tooltip', cachedEntry);
      const { data } = cachedEntry;

      return { data };
    }

    if (this.state && this.date) {
      try {
        const data = await this._fetchData();

        cache[cacheKey] = {
          data,
          timestamp: Date.now(),
        };

        return { data };
      } catch (e) {
        return { errorMessage: 'Error getting apportion rate.' };
      }
    }
  }
}
