import { TooltipToggleHelper } from './tooltipToggleHelper';
import { getMDYFormattedDate } from '../../../../_shared/dateFormatting';
import { getStateTaxRateData } from '../_spreadsheets/apis';
import { transformIntoString } from '../_spreadsheets/stateTaxRateFormulaHelper';
import { STATE_TAX_RATE } from '../../../../_shared/DataReference/ReferenceType';
import { tooltipTogglerCacheTimeout } from '../../../../../configs/params';

const cache = {};

function getCacheKey(args) {
  return JSON.stringify(args);
}

export class StateTaxTooltipToggleHelper extends TooltipToggleHelper {
  constructor(fnArguments) {
    super();
    this.args = fnArguments;
    const [jurisdiction, , taxableIncome] = fnArguments;
    const periodStartDate = transformIntoString(fnArguments[1]);
    this.jurisdiction = jurisdiction;
    this.periodStartDate = periodStartDate;
    this.taxableIncome = taxableIncome;
    this.type = STATE_TAX_RATE;
  }

  async _fetchData() {
    const taxRateData = await getStateTaxRateData({
      jurisdiction: this.jurisdiction,
      periodStartDate: this.periodStartDate,
      taxableIncome: this.taxableIncome,
    });
    const { tax_law_summary, tax_law_summary_link, upcomingRateChange, upcomingRateChangeDate, default_1, rate_type } =
      taxRateData.message;

    let upcomingRateMsg = '';
    if (rate_type && rate_type !== null && upcomingRateChangeDate) {
      const upcomingRateDateFormatted = getMDYFormattedDate(upcomingRateChangeDate);

      if (rate_type === 'Fixed') {
        upcomingRateMsg = `The corporate income tax rate for ${this.jurisdiction} will change from ${default_1} to ${upcomingRateChange}, effective ${upcomingRateDateFormatted}.`;
      } else if (rate_type === 'NotFixed') {
        upcomingRateMsg = `The corporate income tax rate table for ${this.jurisdiction} will change, effective ${upcomingRateDateFormatted}.`;
      }
    }

    return {
      data: { tooltipContent: tax_law_summary, link: tax_law_summary_link },
      upcomingRateMsg,
    };
  }

  async fetchData() {
    const cacheKey = getCacheKey(this.args);
    const cachedEntry = cache[cacheKey];

    if (cachedEntry && Date.now() - cachedEntry.timestamp < tooltipTogglerCacheTimeout) {
      console.log('Returning cached data for state tax rate tooltip', cachedEntry);
      const { data, upcomingRateMsg } = cachedEntry;

      return { data, upcomingRateMsg };
    }

    if (this.jurisdiction && this.periodStartDate) {
      try {
        const { data, upcomingRateMsg } = await this._fetchData();
        cache[cacheKey] = {
          data,
          upcomingRateMsg,
          timestamp: Date.now(),
        };

        return { data, upcomingRateMsg };
      } catch (_) {
        return { errorMessage: 'Error getting state tax rate.' };
      }
    }
  }
}
