import * as wkpFetch from '../../../_shared/fetch';
import { workpapersSpreadsheetModelHostName } from '../../../../configs/params';

export async function getWorkpaperDataReferences({ workpaperId, sheetName = '', filters = new Map() }) {
  let urlFilter = '';
  for (const [k, v] of filters) {
    urlFilter += `${k}=${v}`;
  }
  const response = await wkpFetch.get(
    `${workpapersSpreadsheetModelHostName}/datareferences/${workpaperId}${sheetName && '/' + sheetName}${urlFilter && '?' + urlFilter}`
  );
  if (!response.ok) {
    return null;
  }
  return await response.json();
}

export async function createWorkpaperDataReferences(workpaperId, metadata) {
  const body = { details: metadata };
  body.workpaperId = workpaperId;

  const response = await wkpFetch.post(`${workpapersSpreadsheetModelHostName}/datareferences/create/${workpaperId}`, {
    body: JSON.stringify(body),
  });
  if (!response || !response.ok) {
    return null;
  }
  try {
    return await response.json();
  } catch {
    return null;
  }
}

export async function resolveFormulaValues(workpaperId, outputRequest) {
  const requestBody = JSON.stringify(outputRequest);
  const response = await wkpFetch.post(`${workpapersSpreadsheetModelHostName}/datareferences/resolve/${workpaperId}`, {
    body: requestBody,
  });

  window.dataReferenceResolve && window.dataReferenceResolve.resolve();

  return response;
}

export async function updateReferences(workpaperId, references) {
  const body = JSON.stringify({
    workpaperId,
    details: references.map(({ row, column, id: referenceId, parameters, value, sheetName }) => ({
      row,
      column,
      referenceId,
      newValue: value,
      sheetName,
      parameters: typeof parameters === 'string' ? parameters : JSON.stringify(parameters),
    })),
  });
  return await wkpFetch.put(`${workpapersSpreadsheetModelHostName}/datareferences/${workpaperId}`, {
    body,
  });
}

export async function deleteReferences(workpaperId, referenceIds) {
  const body = JSON.stringify({
    workpaperId: workpaperId,
    referenceIds,
  });
  const response = await wkpFetch.remove(`${workpapersSpreadsheetModelHostName}/datareferences/${workpaperId}`, {
    body,
  });
  if (!response.ok) {
    throw await response.json();
  }
  return response;
}

export async function getAssociatedDataTransformations(workpaperId, taxPeriod, currTaxPeriod) {
  const response = await wkpFetch.get(
    `${workpapersSpreadsheetModelHostName}/datareferences/${workpaperId}/tax-period/${taxPeriod}/current-tax-period/${currTaxPeriod}`
  );
  if (response.status !== 200) {
    return null;
  }
  const data = await response.json();
  return data;
}
