import React from 'react';
import { fixedAssetsLoginPageUrl } from '../../../../configs/params';
import CustomIcon from './CustomIcon/CustomIcon';

export const applications = [
  {
    link: `${fixedAssetsLoginPageUrl}/fa`,
    name: 'Fixed Assets',
    icon: <CustomIcon name="fixed-assets" />,
  },
  {
    link: 'https://provision.bloombergtax.com/',
    name: 'Provision',
    icon: <CustomIcon name="provision" />,
  },
  {
    link: 'https://bloombergtax.com/',
    name: 'Research',
    icon: <CustomIcon name="research" />,
  },
  {
    link: '/data-flows',
    name: 'Workpapers',
    icon: <CustomIcon name="workpapers" />,
  },
];
