import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import Frame from '../_shared/Frame';
import Spreadsheet from './EditorPage/Spreadsheet';
import { initSpreadjs } from './EditorPage/Spreadsheet/_spreadsheets';
import { initializeTooltip } from './EditorPage/tooltip';
import './EditorPage/styles.scss';
import useWorkpaper from './EditorPage/useWorkpaper';
import WorkpaperActionModal from '../_shared/FileMenuModal/WorkpaperActionModal';
import useHidePanelOnClick from '../../leftPanelCloser';
import WorkbookManager from '../_shared/WorkbookManager';
import { ConfirmModal } from '../global-components/Confirmation/ConfirmModal';
import { useMetadata } from './EditorPage/Spreadsheet/SideBar/SettingsPanel/useMetadata';
import EditorContext from './EditorPage/EditorContext';
import ErrorRetrievingRole from '../data-flows/ErrorRetrievingRole';
import { RENEW_LOCK_INTERVAL_MS } from './EditorPage/Spreadsheet/shared/constants';

const tooltipManager = initializeTooltip();
initSpreadjs();

function createNavigator(history) {
  return {
    toEditor(id, newTab = false) {
      history.push(`/editor/${id}`, {
        newEmptyWorkpaper: newTab,
      });
    },
    toHome() {
      history.push('/');
    },
    toLink(id, linkName) {
      history.push(`/editor/${id}/?link=${linkName}`);
    },
  };
}

export default function EditorPage() {
  const { id, versionId } = useParams();
  const { getMetadata } = useMetadata({ workpaperId: id });
  const {
    workbookName,
    setWorkbookName,
    setGlobalTemplate,
    tooltipManagerRef,
    cellReviewFunctionManager,
    taxPeriod,
    setTaxPeriod,
    setIsLoading,
  } = useContext(EditorContext);
  tooltipManagerRef.current = tooltipManager;
  const history = useHistory();
  const navigator = createNavigator(history);
  const [fileMenuAction, setFileMenuAction] = useState();
  const { load, lockCleanup, setConfirmModalVisible, lockInfo, loadWorkbookFailed, isConfirmModalVisible } =
    useWorkpaper({
      id,
      versionId,
      navigator,
    });
  const workbookManager = WorkbookManager.getInstance();
  useHidePanelOnClick();

  useEffect(() => {
    getMetadata().then(({ name, isGlobalTemplate, taxPeriod }) => {
      setWorkbookName(name);
      setGlobalTemplate(isGlobalTemplate);
      setTaxPeriod(taxPeriod);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = workbookName;
  }, [workbookName]);

  const handleNeedsReload = async () => {
    setIsLoading(true);
    await lockCleanup();
    await new Promise(res => setTimeout(res, RENEW_LOCK_INTERVAL_MS));
    workbookManager.runWorkbookRequest(id);
    load();
  };

  if (loadWorkbookFailed) {
    return <Redirect to={{ pathname: '/workpapers', state: { loadWorkbookFailed, workpaperId: id, workbookName } }} />;
  }

  const handleFileMenuAction = action => {
    setFileMenuAction(action);
  };

  const maxClipboardTextModal = {
    show: isConfirmModalVisible,
    title: 'Information',
    message:
      'You have selected a large number of cells that can not be supported for copy/paste, please reduce your cell selection and try again',
    confirmLabel: 'Ok',
    onConfirm: () => setConfirmModalVisible(false),
    onCancel: () => setConfirmModalVisible(false),
    modalStyle: 'info',
  };

  return (
    <Frame activeItem="editor" className="editor" showAppBar={false}>
      <ErrorRetrievingRole></ErrorRetrievingRole>
      <div id="wkp-editor-container">
        <Spreadsheet
          key={id}
          cellReviewFunctionManager={cellReviewFunctionManager}
          handleFileMenuAction={handleFileMenuAction}
          taxPeriod={taxPeriod}
          tooltipManager={tooltipManager}
          userLockFullName={lockInfo?.userFullName}
          versionId={versionId}
          workpaperId={id}
          onNeedsReload={handleNeedsReload}
        />
      </div>
      {fileMenuAction && (
        <WorkpaperActionModal
          fileMenuAction={fileMenuAction}
          handleFileMenuAction={handleFileMenuAction}
          workpaperId={id}
        />
      )}
      {isConfirmModalVisible && <ConfirmModal modalState={maxClipboardTextModal} />}
    </Frame>
  );
}
