import { useEffect, useRef } from 'react';
import { BroadcastChannels } from './useBroadcast/constants';

const useBroadcast = () => {
  const defaultChannel = window?.BroadcastChannel ? new BroadcastChannel(BroadcastChannels.Default) : undefined;
  const channels = useRef({ [BroadcastChannels.Default]: defaultChannel });

  useEffect(() => {
    if (window?.BroadcastChannel) {
      return () => {
        for (const channelName in channels.current) {
          channels.current[channelName].close();
        }
      };
    }
  }, []);

  /**
   * Creates a new broadcast channel, unless it is already created
   * @param {string} channelName Channel name
   */
  function createChannel(channelName) {
    if (!(channelName in channels.current)) {
      const channel = new BroadcastChannel(channelName);
      const newChannels = { ...channels.current, [channelName]: channel };
      channels.current = newChannels;
    }
  }

  /**
   * Sends an event to an specific channel. Uses the base channel if not channel name is provided
   * @param {object} event Object to send to the channel. This object must contain a type property
   * @param {string?} channelName Channel name to which the event should be send
   */
  function sendMessage(channelName = BroadcastChannels.Default, event) {
    if (!window?.BroadcastChannel) {
      return;
    }

    if (!(channelName in channels.current)) {
      throw new Error(`${channelName} channel does not exist.`);
    }

    const channel = channels.current[channelName];

    channel.postMessage(event);
  }

  /**
   * Subscribe a callback to be executed when an event is received in the channel with a specific event type
   * @param {string} channelName Channel name
   * @param {string} eventType Event type
   * @param {(event) => void} callback Callback to be executed when event with specific `eventType` type is received in the channel `channelName`
   * @returns Method to unsubscribe the subscribed listener from the corresponding channel
   */
  function subscribeToChannel(channelName, eventType, callback) {
    if (!window?.BroadcastChannel) {
      return;
    }

    if (!(channelName in channels.current)) {
      throw new Error(`${channelName} channel does not exist.`);
    }

    const channel = channels.current[channelName];

    const listener = event => {
      if (event.data.type === eventType) {
        callback(event);
      }
    };
    channel.addEventListener('message', listener);

    return () => channel.removeEventListener('message', listener);
  }

  return { createChannel, sendMessage, subscribeToChannel };
};

export default useBroadcast;
