import { useMemo, useReducer } from 'react';
import {
  componentsActions,
  componentsReducer,
  initialComponentsState,
} from './useDataFlowStateReducer/componentsReducer';
import { initialLinksState, linksActions, linksReducer } from './useDataFlowStateReducer/linksReducer';
import { elementsActions, elementsReducer, initialElementsState } from './useDataFlowStateReducer/elementsReducer';
import { infoActions, infoReducer, initialInfoState } from './useDataFlowStateReducer/infoReducer';
import {
  initialState as stateReducerInitialState,
  saveStateActions,
  saveStateReducer,
} from './useDataFlowStateReducer/saveStateReducer';
import { syncElementStateActions, syncElementStateReducer } from './useDataFlowStateReducer/syncElementStateReducer';
import { dataFlowSideBarActions, dataFlowSideBarReducer } from './useDataFlowStateReducer/dataFlowSideBarReducer';
import { initialNotesState, notesActions, notesReducer } from './useDataFlowStateReducer/notesReducer';

const initialState = {
  ...initialInfoState,
  ...initialComponentsState,
  ...initialElementsState,
  ...initialLinksState,
  ...stateReducerInitialState,
  ...initialNotesState,
};

const reducers = [
  componentsReducer,
  elementsReducer,
  linksReducer,
  infoReducer,
  saveStateReducer,
  syncElementStateReducer,
  dataFlowSideBarReducer,
  notesReducer,
];

function init(dataFlow) {
  return {
    ...initialState,
    ...dataFlow,
    notes: dataFlow?.configuration?.notes || {},
  };
}

function reducer(state, action) {
  return reducers.reduce((state, reducer) => {
    return reducer(state, action);
  }, state);
}

export function useDataFlowStateReducer(dataFlow) {
  const [dataFlowState, dispatch] = useReducer(reducer, dataFlow, init);
  const dataFlowActions = useMemo(() => {
    return {
      ...linksActions(dispatch),
      ...componentsActions(dispatch),
      ...elementsActions(dispatch),
      ...saveStateActions(dispatch),
      ...infoActions(dispatch),
      ...syncElementStateActions(dispatch),
      ...dataFlowSideBarActions(dispatch),
      ...notesActions(dispatch),
    };
  }, [dispatch]);

  return [dataFlowState, dataFlowActions];
}
