import { useState, useEffect, useRef } from 'react';
import { ClientSyncService } from '../services/ClientSync.service';
import useBroadcast from '../components/editor/EditorPage/useBroadcast';
import { BroadcastChannels, EventTypes } from '../components/editor/EditorPage/useBroadcast/constants';

export function useClientSync() {
  const syncServiceRef = useRef();
  const [clientId, setClientIdState] = useState(() => {
    if (!syncServiceRef.current) {
      syncServiceRef.current = new ClientSyncService();
    }
    return syncServiceRef.current.getStoredClient();
  });

  const { createChannel, sendMessage, subscribeToChannel } = useBroadcast();

  useEffect(() => {
    createChannel(BroadcastChannels.ClientSync);

    const syncService = syncServiceRef.current;

    const unsubscribeStorage = syncService.subscribe(setClientIdState);

    const unsubscribeBroadcast = subscribeToChannel(BroadcastChannels.ClientSync, EventTypes.ClientChanged, event => {
      setClientIdState(event.data.clientId);
    });

    return () => {
      unsubscribeStorage();
      if (unsubscribeBroadcast) {
        unsubscribeBroadcast();
      }
    };
  }, [createChannel, subscribeToChannel]);

  const setClientId = newClientId => {
    syncServiceRef.current.setClient(newClientId);
    setClientIdState(newClientId);

    sendMessage(BroadcastChannels.ClientSync, {
      type: EventTypes.ClientChanged,
      clientId: newClientId,
    });
  };

  return { clientId, setClientId };
}
