import { useEffect } from 'react';
import { createPortal } from 'react-dom';

const TooltipTogglerPortal = ({ children, parent, className }) => {
  const element = document.createElement('div');

  useEffect(() => {
    const target = parent?.appendChild ? parent : document.body;
    element.classList.add('portal-container');
    target.appendChild(element);
    return () => {
      target.removeChild(element);
    };
  }, [element, parent, className]);

  return createPortal(children, element);
};

export default TooltipTogglerPortal;
