import { getUser } from '../../../../_shared/auth';
import { getPresignedUrl, uploadWorkpaper } from '../WorkpaperSendToTPModal/apis';
import { format } from 'date-fns';
import { IntegrationType } from '../WorkpaperSendToTPModal/constants';
import { SOURCE_FILE_AREA } from './constants';
import { v4 as uuid } from 'uuid';
import CustomLogger from '../../../../_shared/Logger/CustomLogger';

const formatFilename = (sourceWorkpaperId, destination, workbookName, taxPeriod, sheetName) => {
  if (destination === IntegrationType.TaxProvision) {
    const fileTime = format(new Date(), 'yyyyMMddHHmmssSSSS');
    return `${sourceWorkpaperId}-${fileTime}.xlsx`;
  }
  //SFTP file name
  taxPeriod = taxPeriod ? `-${taxPeriod}` : '';
  return `${workbookName}${taxPeriod}-${sheetName}.xlsx`;
};

const generateKey = (fileName, destination) => {
  const userInfo = getUser();
  if (destination === IntegrationType.TaxProvision) {
    const { companyId = null } = userInfo != null && (typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo);
    return `TP_EXPORT/${companyId}/spreadsheet/${fileName}`;
  }
  //SFTP path KEY
  const { companyId } = userInfo;
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = (now.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = now.getUTCDate().toString().padStart(2, '0');
  return `${companyId}/${SOURCE_FILE_AREA}/${year}/${month}/${day}/${uuid()}-${fileName}`;
};

const workbookExport = async (excelIO, json) => {
  return new Promise((resolve, reject) => {
    excelIO.save(
      json,
      blob => {
        resolve(blob);
      },
      error => {
        reject(error);
      }
    );
  });
};

export async function processWorkbookForExport(sourceWorkpaperId, destination, workbookName = null, taxPeriod = null) {
  const loggerOperation =
    destination === IntegrationType.TaxProvision
      ? CustomLogger.operations.SEND_TO_TP
      : CustomLogger.operations.SEND_TO_SFTP;

  const { GC } = window;

  const spread = GC.Spread.Sheets.findControl(document.querySelector('[gcuielement="gcSpread"]'));
  const activeSheet = spread.getActiveSheet();
  const activeSheetData = activeSheet.toJSON();

  for (let row = 0; row < activeSheet.getRowCount(); row++) {
    for (let col = 0; col < activeSheet.getColumnCount(); col++) {
      const cell = activeSheet.getCell(row, col);
      if (cell.formula()) {
        if (!activeSheetData.data.dataTable[row]) {
          activeSheetData.data.dataTable[row] = [];
        }
        if (!activeSheetData.data.dataTable[row][col]) {
          activeSheetData.data.dataTable[row][col] = {};
        }
        activeSheetData.data.dataTable[row][col].value = cell.value();
        delete activeSheetData.data.dataTable[row][col].formula;
      }
    }
  }

  CustomLogger.pushLog(loggerOperation, {
    spreadsheet_id: sourceWorkpaperId,
    message: 'Formulas linked to other sheets were correctly replaced by their values.',
  });

  const tempElement = document.createElement('DIV');
  const newSpread = new GC.Spread.Sheets.Workbook(tempElement);
  const newSheet = newSpread.getSheet(0);
  newSheet.name(activeSheet.name());
  newSheet.fromJSON(activeSheetData);

  const excelIO = new GC.Spread.Excel.IO();
  try {
    const blob = await workbookExport(excelIO, newSpread.toJSON());
    const fileSize = blob.size;
    const fileName = formatFilename(sourceWorkpaperId, destination, workbookName, taxPeriod, activeSheetData.name);

    const location = generateKey(fileName, destination);
    const uploadPresignedResult = await getPresignedUrl(fileName, location, true);
    const uploadPresignedUrl = uploadPresignedResult.presignedUrls.find(output => output[fileName])?.[fileName];
    await uploadWorkpaper(blob, uploadPresignedUrl);

    CustomLogger.pushLog(loggerOperation, {
      spreadsheet_id: sourceWorkpaperId,
      message: `workbook file ${fileName} on resourceId: ${sourceWorkpaperId} was upload successfully to integration bucket, key: ${location}`,
    });

    return { location, fileName, fileSize };
  } catch (err) {
    CustomLogger.pushLog(loggerOperation, {
      spreadsheet_id: sourceWorkpaperId,
      error: JSON.stringify(err),
      message: `Something happen processing the workbook to be exported`,
    });
    throw err;
  } finally {
    tempElement.remove();
  }
}
