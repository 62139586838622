import { BTButton, BTModal, BTTextarea } from '@btas/jasper';
import React, { useContext, useEffect, useState } from 'react';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';

export function NoteModal({ id, show = true, onClose, onSubmit }) {
  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);
  const canEditWorkflow = useCanEditWorkflow();
  const [note, setNote] = useState('');

  useEffect(() => {
    if (show && dataFlowState.notes) {
      setNote(dataFlowState.notes[id] || '');

      document.querySelector('.bbui-dialog-content-container').style = 'width: 400px !important;';
    }
  }, [id, show, dataFlowState.notes]);

  const handleSubmit = () => {
    dataFlowActions.setElementNote(id, note);

    onSubmit();
  };

  const handleCancel = () => {
    setNote('');

    onClose();
  };

  return (
    <BTModal show={show} size="modal-sm" title="Note" onCloseClick={handleCancel}>
      <BTModal.Body>
        <BTTextarea
          inputHeight={200}
          isReadonly={!canEditWorkflow}
          maxLength={1000}
          value={note}
          onChange={e => setNote(e.currentTarget.value)}
        />
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={handleCancel}>{canEditWorkflow ? 'Cancel' : 'Close'}</BTButton>
        {canEditWorkflow && (
          <BTButton btStyle="primary" onClick={handleSubmit}>
            Apply
          </BTButton>
        )}
      </BTModal.Footer>
    </BTModal>
  );
}
