export class ClientSyncService {
  constructor() {
    this.STORAGE_KEY = 'client-id';
  }

  getStoredClient() {
    return localStorage.getItem(this.STORAGE_KEY);
  }

  setClient(clientId) {
    localStorage.setItem(this.STORAGE_KEY, clientId);
  }

  subscribe(callback) {
    const handleStorageChange = event => {
      if (event.key === this.STORAGE_KEY) {
        callback(event.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }
}
