import GC from '../../../../../../SpreadSheets';
import { setValueOnServer } from '../../../useWorkpaper/processCommand';

export const taxPeriodFormulaName = 'TAX_PERIOD';

export class TaxPeriodFormula extends GC.Spread.CalcEngine.Functions.Function {
  tax_period;
  workpaperId;
  enqueueCommands;

  constructor(tax_period, workpaperId, enqueueCommands, spread) {
    super();

    this.name = taxPeriodFormulaName;
    this.minArgs = 0;
    this.maxArgs = 0;

    this.tax_period = tax_period;
    this.workpaperId = workpaperId;
    this.enqueueCommands = enqueueCommands;
    this.spread = spread;
  }

  evaluate() {
    const sheet = this.spread.getActiveSheet();
    const row = sheet.getActiveRowIndex();
    const column = sheet.getActiveColumnIndex();
    const formula = sheet.getFormula(row, column);
    setTimeout(() => {
      if (formula && formula.startsWith(this.name)) {
        const command = setValueOnServer(sheet, row, column, this.tax_period);
        this.enqueueCommands([{ commandText: JSON.stringify(command) }]);
      }
    }, 1);
    return this.tax_period;
  }

  description() {
    return {
      name: taxPeriodFormulaName,
      description: 'Returns the tax period of the current workpaper.',
    };
  }
}
