import { attachSheetNameValidation } from './sheetNameValidation';
import { attachClipboardFunctionality } from './clipboard';
import { attachCommandsBehavior, overrideContextMenuFilterCommand } from './commandsBehavior';
import { createGoToSourceDataFlowCommand } from './commands/goToSourceDataFlow';
import { createCopySheetCommand } from './commands/copySheet';
import { createSendToTaxProvisionCommand } from './commands/sendToTaxProvision';
import { createSendToSFTPCommand } from './commands/sendToSFTP';
import { createCellReviewCommand } from './commands/cellReviewCommand';
import { resetDefaultBrowserBehavior } from './browserDefaultReset';
import { overrideShortcuts } from './commands/shortcuts/shortcutsOverrides';
import { createRefreshDataLinksCommand } from './commands/refreshDataLinks';

/**
 * Initialize some global spreadjs behavior such as helpers on GC.Spread.Sheets.Worksheet object and cell overrides.
 */
export function initSpreadjs() {
  resetDefaultBrowserBehavior();
  overrideContextMenuFilterCommand();
}

/**
 * Attach custom behavior to the spread, such as linking functionality and auto-save
 * @param {object} args { spread, workpaperId, enableAutoSave }
 */
export function attachBehavior({ spread, workpaperId, tooltipManager, setCommandsVisibleContext }) {
  attachCommandsBehavior({ spread, setCommandsVisibleContext });
  attachClipboardFunctionality({ spread, workpaperId });
  attachSheetNameValidation(spread);
  overrideShortcuts(spread);
  tooltipManager.captureTooltipSectionByElement(document.getElementById('designer'));
}

export { setCellDataSourceFormula, setStateTaxRateCell, registerGlobalCustomFunctions } from './formulas';
export {
  executeWithSuspendedPaint,
  isCellReference,
  getValueFromCellReference,
  isCalcError,
  formulaHelpers,
} from './spreadHelpers';

export function addCustomCommands(config, args) {
  const goToSourceDataFlowCommandConfig = createGoToSourceDataFlowCommand(args);
  const createCellReviewCommandConfig = createCellReviewCommand(args);
  const createCopySheetCommandConfig = createCopySheetCommand(args);
  const createSendToTaxProvisionCommandConfig = createSendToTaxProvisionCommand(args);
  const createSendToSFTPCommandConfig = createSendToSFTPCommand(args);
  const refreshDataLinksCommand = createRefreshDataLinksCommand(args);
  return {
    ...config,
    commandMap: {
      ...config.commandMap,
      ...goToSourceDataFlowCommandConfig,
      ...createCellReviewCommandConfig,
      ...createCopySheetCommandConfig,
      ...createSendToTaxProvisionCommandConfig,
      ...createSendToSFTPCommandConfig,
      ...refreshDataLinksCommand,
    },
  };
}
