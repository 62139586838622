import { getTokenInfo } from '../components/_shared/auth';

export const roles = Object.freeze({
  CPOC_ADMIN: 'CPOC_ADMIN',
});

export const hasUserRole = role => {
  const tokenInfo = getTokenInfo();
  const userRoles = tokenInfo?.preferences;
  switch (role) {
    case roles.CPOC_ADMIN:
      return !!userRoles && userRoles.find(userRole => userRole.scope === 'cpoc' && userRole.value === 'admin');
    default:
      return false;
  }
};
