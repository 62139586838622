import { useContext } from 'react';
import { DataFlowEditorContext } from '../../DataFlowEditorPage/DataFlowEditorContext';
import SaveChangesPrompt from './SaveChangesPrompt';

const CmDirtyStateHandler = () => {
  const { dataFlowState } = useContext(DataFlowEditorContext);
  const { isDirty } = dataFlowState;

  return <SaveChangesPrompt when={isDirty} />;
};

export default CmDirtyStateHandler;
