import { useEffect, useReducer, useState } from 'react';
import usePubSub from '../PubSub/usePubSub';
import MessageType from '../../../_shared/PubSub/pubSubMessageType';
import { v4 as uuidv4 } from 'uuid';
import { saveCommands } from './useCommandsQueue/apis';
import { getDataFromLocalStorage } from '../../../_shared/storage';

const initialState = {
  commandsQueue: [],
  isCommandsSaving: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'addCommand':
      return { ...state, commandsQueue: [...state.commandsQueue, ...action.payload] };
    case 'startProcessQueue':
      return { ...state, isCommandsSaving: true };
    case 'endProcessQueue':
      return {
        ...state,
        isCommandsSaving: false,
        commandsQueue: state.commandsQueue.filter(c => !action.payload.some(p => c.commandId === p.commandId)),
      };
    case 'errorProcessQueue':
      return { ...state, isCommandsSaving: false };
    default:
      throw new Error();
  }
}

export default function useCommandsQueue({
  spreadRef,
  dataReferenceWorkpaperId,
  syncDatareferences,
  lastCommandAppliedTime,
  commandsProcessingError,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [workpaperId, setWorkpaperId] = useState();
  const { publish } = usePubSub();

  const syncDatareferencesPositionsData = async commands => {
    commands.forEach(commandRequest => {
      const command = JSON.parse(commandRequest.commandText);
      publish({
        body: { command, sheet: spreadRef.current.getSheetFromName(command.sheetName) },
        message: MessageType.CellPosition,
        callback: syncDatareferences?.trackDataReferencesAction.current,
      });
    });
  };

  const sendCommands = async commands => {
    if (commands.length > 0 && !state.isCommandsSaving) {
      dispatch({ type: 'startProcessQueue' });
      const sheetName = spreadRef.current?.getActiveSheet()?.name();
      syncDatareferencesPositionsData(commands);

      const response = await saveCommands(
        dataReferenceWorkpaperId.current,
        sheetName,
        commands,
        lastCommandAppliedTime
      );

      if (response.ok) {
        dispatch({ type: 'endProcessQueue', payload: commands });
        // eslint-disable-next-line no-useless-escape
        if (commands.filter(x => x.commandText.includes('{"cmd":"renameSheet"'))?.length) {
          publish({
            body: workpaperId,
            message: MessageType.CellPosition,
            callback: syncDatareferences?.loadDataReferencesAction.current,
          });
        }
      } else if (response.status === 409) {
        window.location.reload();
      } else if (response.status >= 500) {
        commandsProcessingError.current = true;
      }
    }
  };

  useEffect(() => {
    if (state.commandsQueue.length > 0) {
      sendCommands([...state.commandsQueue]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const enqueueCommands = commands => {
    commands.forEach(command => {
      command.commandId = uuidv4();
      command.creationDate = new Date().toISOString();
      command.sessionId = getDataFromLocalStorage(`sessionId-${dataReferenceWorkpaperId.current}`);
    });
    dispatch({ type: 'addCommand', payload: commands });
  };

  return {
    isCommandsSaving: state.isCommandsSaving,
    commandsQueue: state.commandsQueue,
    enqueueCommands,
    allCommandsProcessedAsync: () => {},
    setWorkpaperId,
  };
}
