import { tooltipTogglerCacheTimeout } from '../../../../../configs/params';
import { INT_TAX_RATE } from '../../../../_shared/DataReference/ReferenceType';
import { getInternationalTaxRateData } from '../_spreadsheets/apis';
import { TooltipToggleHelper } from './tooltipToggleHelper';

const cache = {};

function getCacheKey(args) {
  return JSON.stringify(args);
}

export class IntTaxRateTooltipToggleHelper extends TooltipToggleHelper {
  constructor(fnArguments) {
    super();
    this.args = fnArguments;
    this.country = fnArguments[0];
    this.periodStartDate = fnArguments[1];
    this.taxableIncome = fnArguments[2];
    this.taxRateData = null;
    this.type = INT_TAX_RATE;
  }

  buildTooltipMessage() {
    const { country, taxRate, taxRates, taxRateType, severalIndustryTypes } = this.taxRateData;

    const formatTaxRate = taxRate => `${Math.round(taxRate * 100) / 100}%`;

    let { date } = this.taxRateData;
    if (/\d{4}-\d{1,2}-\d{1,2}/.test(date)) {
      const dateSplit = date.split('-');
      date = `${dateSplit[1]}-${dateSplit[2]}-${dateSplit[0]}`;
    }

    let message;
    if (taxRateType === 'Fixed') {
      const taxRatePercentage = formatTaxRate(taxRate);
      message = `The ${country} corporate income tax rate for tax years beginning ${date} is ${taxRatePercentage}.`;
    } else {
      const sortedTaxRates = taxRates.map(parseFloat).sort((a, b) => a - b);
      const firstRate = formatTaxRate(sortedTaxRates[0]);
      const lastRate = formatTaxRate(sortedTaxRates[taxRates.length - 1]);
      message = `The ${country} corporate income tax rate for tax years beginning ${date} ranges from ${firstRate} to ${lastRate}.`;
    }

    if (message && severalIndustryTypes) {
      message +=
        'BOLDThere may be different rates applicable depending on your circumstances, ' +
        'such as for small businesses or specialized industries.BOLD';
    }

    return message;
  }

  async _fetchData() {
    this.taxRateData = await getInternationalTaxRateData({
      country: this.country,
      periodStartDate: this.periodStartDate,
      taxableIncome: this.taxableIncome,
    });

    return {
      tooltipContent: this.buildTooltipMessage(),
      link: this.taxRateData.citations,
    };
  }

  async fetchData() {
    const cacheKey = getCacheKey(this.args);
    const cachedEntry = cache[cacheKey];

    if (cachedEntry && Date.now() - cachedEntry.timestamp < tooltipTogglerCacheTimeout) {
      console.log('Returning cached data for int tax rate tooltip', cachedEntry);
      const { data } = cachedEntry;

      return { data };
    }

    if (this.country && this.periodStartDate) {
      try {
        const data = await this._fetchData();

        cache[cacheKey] = {
          data,
          timestamp: Date.now(),
        };

        return {
          data,
        };
      } catch (_) {
        return { errorMessage: 'Error getting international tax rate.' };
      }
    }
  }
}
