import mockClients from './mockClients.json';

export async function getClients(companyId) {
  const testCompanyId = '990000003';
  if (companyId === testCompanyId) {
    return [mockClients[0]];
  } else {
    return mockClients;
  }
}
