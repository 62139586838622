import { AnnotationElementType } from '../elementType/AnnotationElementType';
import { ElementTypeMap } from '../elementType/types';

function getBounds(bbox) {
  return {
    left: bbox.x,
    top: bbox.y,
    height: bbox.height,
    width: bbox.width,
  };
}

function transformElements(elements) {
  return Object.values(elements).reduce((res, { id, type, elementData, cell }) => {
    const typeData = ElementTypeMap[type].extractTypeData(elementData);

    return {
      ...res,
      [id]: {
        ...typeData,
        id,
        type,
        name: elementData.name,
        bounds: getBounds(cell.getBBox()),
      },
    };
  }, {});
}

function transformAnnotations(elements) {
  return Object.values(elements).reduce((res, { id, type, elementData, cell }) => {
    const typeData = ElementTypeMap[type].extractTypeData(elementData);

    return {
      ...res,
      [id]: {
        ...typeData,
        id,
        type,
        name: elementData.name,
        bounds: getBounds(cell.getBBox()),
        text: cell.attributes.attrs.label?.text,
      },
    };
  }, {});
}

function transformConnections(connections) {
  return connections.map(connection => ({
    id: connection.id,
    sourceId: connection.sourceId,
    sourcePort: connection.sourcePort,
    targetId: connection.targetId,
    targetPort: connection.targetPort,
    vertices: connection?.vertices,
  }));
}

export function transformToConfiguration(elements, connections, workflowConfiguration, notes = {}) {
  const [transformationElements, annotationElements] = Object.values(elements).reduce(
    (elements, element) => {
      return element.type !== AnnotationElementType.TYPE
        ? (elements[0].push(element), elements)
        : (elements[1].push(element), elements);
    },
    [[], []]
  );

  return {
    elements: transformElements(transformationElements),
    connections: transformConnections(connections),
    annotations: transformAnnotations(annotationElements),
    notes,
    workflowConfiguration: workflowConfiguration ?? {
      date: null,
      status: { label: 'Not Started', value: 'Not Started' },
      userId: null,
    },
  };
}
