import React, { memo } from 'react';

const CustomIcon = ({ name }) => {
  switch (name) {
    case 'compliance-tracker':
      return (
        <svg fill="none" height="22" viewBox="0 0 22 22" width="22">
          <path d="M3.66667 0H5.5V5.5H3.66667V0Z" fill="white" />
          <path d="M16.5 0H18.3333V5.5H16.5V0Z" fill="white" />
          <path
            clipRule="evenodd"
            d="M20.1667 7.33529V3.66667H22V22H0V3.66667H1.83333V7.33529H7.3326V3.66667H14.6667V7.33529H20.1667ZM1.83333 20.1667H20.1667V9.77778H1.83333V20.1667Z"
            fill="white"
            fillRule="evenodd"
          />
          <path
            d="M15.1736 12.8731L14.2986 12L9.83593 16.4533L7.78403 14.4057L6.90909 15.2788L9.83703 18.1983L15.1736 12.8731Z"
            fill="white"
            stroke="white"
          />
        </svg>
      );
    case 'research':
      return (
        <svg fill="none" height="18" viewBox="0 0 18 18" width="18">
          <path
            clipRule="evenodd"
            d="M11.5812 13.7476C10.4044 14.5184 8.99722 14.9666 7.48536 14.9666C3.35131 14.9666 0 11.6153 0 7.48121C0 3.34716 3.35131 -0.00415039 7.48536 -0.00415039C11.6194 -0.00415039 14.9707 3.34716 14.9707 7.48121C14.9707 9.02057 14.5061 10.4514 13.7093 11.6411L17.5553 15.4423C18.1434 16.0235 18.1489 16.9714 17.5677 17.5595C16.9865 18.1475 16.0386 18.1531 15.4506 17.5718L11.5812 13.7476ZM12.7251 7.48121C12.7251 10.375 10.3792 12.721 7.48536 12.721C4.59153 12.721 2.24561 10.375 2.24561 7.48121C2.24561 4.58738 4.59153 2.24146 7.48536 2.24146C10.3792 2.24146 12.7251 4.58738 12.7251 7.48121Z"
            fill="white"
            fillRule="evenodd"
          />
          <path
            d="M5.04956 10.2936L6.14807 9.51025C6.41819 9.96946 6.94944 10.1676 7.35462 10.1676C7.65176 10.1676 8.01193 10.0145 8.01193 9.70834C8.01193 9.31216 7.66076 9.1861 6.83238 8.89797C5.94097 8.59183 5.24765 8.25868 5.24765 7.36727C5.24765 6.75498 5.60782 6.3588 6.02201 6.12469C5.70686 5.89058 5.54479 5.53942 5.54479 5.10722C5.54479 3.99071 6.69732 3.51349 7.61574 3.51349C8.49815 3.51349 9.2545 3.83764 9.66869 4.44092L8.62421 5.23328C8.40811 4.90013 8.02093 4.69303 7.67877 4.69303C7.38164 4.69303 7.03948 4.82809 7.03948 5.13424C7.03948 5.45838 7.39964 5.62946 8.09296 5.87258C8.99338 6.20573 9.74973 6.61092 9.74973 7.53834C9.74973 8.01556 9.4796 8.43876 9.07442 8.66386C9.39857 8.88897 9.52462 9.25814 9.52462 9.65432C9.52462 10.8969 8.32707 11.3831 7.32761 11.3831C6.47222 11.3831 5.52678 11.0139 5.04956 10.2936ZM7.29159 7.80847L8.13798 8.14162C8.31807 8.06059 8.41711 7.8715 8.41711 7.68241C8.41711 7.32224 8.07496 7.15117 7.71479 6.9981L6.94043 6.68295C6.74234 6.76399 6.57126 6.90805 6.57126 7.16017C6.57126 7.52934 6.92242 7.6644 7.29159 7.80847Z"
            fill="white"
          />
        </svg>
      );
    case 'workpapers':
      return (
        <svg fill="none" height="20" viewBox="0 0 19 20" width="19">
          <path
            clipRule="evenodd"
            d="M17.5 0C17.9602 0 18.3333 0.373096 18.3333 0.833333V19.1667C18.3333 19.6269 17.9602 20 17.5 20H0.833334C0.373097 20 3.10775e-07 19.6269 3.01997e-07 19.1667L0 3.67851C0 3.4575 0.0877974 3.24554 0.244078 3.08926L3.08926 0.244077C3.24554 0.0877971 3.4575 0 3.67851 0H17.5ZM15.1667 12.6H7.56667V16H15.1667V12.6ZM3.16667 12.6H6.76667V16H3.16667V12.6ZM15.1667 8.20001H7.56667V11.6H15.1667V8.20001ZM3.16667 8.20001H6.76667V11.6H3.16667V8.20001ZM6.76667 4.00001H3.16667V7.20001H6.76667V4.00001ZM7.56667 4.00001H15.1667V7.20001H7.56667V4.00001Z"
            fill="white"
            fillRule="evenodd"
          />
        </svg>
      );
    case 'provision':
      return (
        <svg fill="none" height="20" viewBox="0 0 20 20" width="20">
          <path
            clipRule="evenodd"
            d="M0.761228 0.865265C0.340814 0.865265 0 1.20608 0 1.62649V18.3735C0 18.7939 0.340813 19.1347 0.761228 19.1347H12.0813C10.9439 18.3361 10.2005 17.0143 10.2005 15.5189C10.2005 13.0805 12.1772 11.1038 14.6156 11.1038V1.62649C14.6156 1.20608 14.2748 0.865265 13.8544 0.865265H0.761228ZM12.6745 2.9967H1.94113V5.661H12.6745V2.9967ZM1.94113 7.67826H4.90992V10.1142H1.94113V7.67826ZM8.79219 7.67826H5.8234V10.1142H8.79219V7.67826ZM1.94113 11.0277H4.90992V13.4636H1.94113V11.0277ZM8.79219 11.0277H5.8234V13.4636H8.79219V11.0277ZM1.94113 14.3771H4.90992V16.813H1.94113V14.3771ZM8.79219 14.3771H5.8234V16.813H8.79219V14.3771ZM9.70566 7.67826H12.6745V10.1142H9.70566V7.67826Z"
            fill="white"
            fillRule="evenodd"
          />
          <path
            d="M18.554 13.8102L17.8824 13.14L14.457 16.5582L12.882 14.9866L12.2104 15.6568L14.4579 17.8977L18.554 13.8102Z"
            fill="white"
            stroke="white"
          />
        </svg>
      );
    case 'fixed-assets':
      return (
        <svg fill="none" height="20" viewBox="0 0 14 20" width="14">
          <path
            clipRule="evenodd"
            d="M0.833333 0C0.373096 0 0 0.373096 0 0.833334V19.1667C0 19.6269 0.373096 20 0.833333 20H7.75V16.25H10.75V20H12.9167C13.3769 20 13.75 19.6269 13.75 19.1667V0.833333C13.75 0.373096 13.3769 0 12.9167 0H0.833333ZM6 3H3V5.66667H6V3ZM7.75 3H10.75V5.66667H7.75V3ZM6 7.41667H3V10.0833H6V7.41667ZM7.75 7.41667H10.75V10.0833H7.75V7.41667ZM6 11.8333H3V14.5H6V11.8333ZM7.75 11.8333H10.75V14.5H7.75V11.8333Z"
            fill="white"
            fillRule="evenodd"
          />
        </svg>
      );
    default:
      return null;
  }
};

export default memo(CustomIcon);
