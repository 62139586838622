import { SAVE_STATE } from './saveStateReducer';

export const initialNotesState = {
  notes: {},
};

export const NotesActions = {
  setElementNote: 'set-element-note',
  setElementsNotes: 'set-elements-notes',
};

export const notesActions = dispatch => ({
  setElementNote: (id, text) => dispatch({ type: NotesActions.setElementNote, id, text }),
  setElementsNotes: notes => dispatch({ type: NotesActions.setElementsNotes, notes }),
});

export const notesReducer = (state, action) => {
  switch (action.type) {
    case NotesActions.setElementNote:
      const isEqual = state.notes && state.notes[action.id] === action.text;

      if (isEqual) {
        return state;
      }

      return {
        ...state,
        notes: {
          ...state.notes,
          [action.id]: action.text,
        },
        isDirty: true,
        saveMenu: { saveButton: false, saveAndPubButton: false, exportButton: true, state: SAVE_STATE.dirty },
      };
    case NotesActions.setElementsNotes:
      return {
        ...state,
        notes: {
          ...state.notes,
          ...action.notes,
        },
      };
    default:
      return state;
  }
};
