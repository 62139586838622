import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { BTPopover, BTButton } from '@btas/jasper';
import GC from '../../../../SpreadSheets';
import EditorContext from '../EditorContext';
import { TooltipToggleHelper } from './TooltipToggler/tooltipToggleHelper';
import { createTooltipToggleHelper } from './TooltipToggler/tooltipToggleFactory';
import {
  INT_TAX_RATE,
  SOURCE_DATA_CONNECTION,
  STATE_APPORTION,
  STATE_TAX_RATE,
} from '../../../_shared/DataReference/ReferenceType';
import { TAX_LAW, tooltipConfigs } from './TooltipToggler/tooltipConfig';
import { getFakeEvent, itemTypes, setPropValues } from '../../../_shared/EventTrackingContext/utils';
import { eventNames } from '../../../_shared/EventTrackingContext/constants';
import { EventTrackingContext } from '../../../_shared/EventTrackingContext';
import TooltipTogglerPortal from './TooltipToggler/tooltipTogglerPortal';

let headerHeight;
let leftNavBarWidth;
const gavelPositioningGap = 5;

const typeToTrackingTagMap = {
  [SOURCE_DATA_CONNECTION]: 'source-data-connection-tooltip',
  // To be authorized by analytics team
  // [STATE_TAX_RATE]: 'state-tax-rate-tooltip',
  // [INT_TAX_RATE]: 'int-tax-rate-tooltip',
  // [STATE_APPORTION]: 'state-apportion-tooltip',
};

function TooltipToggler({ isLoading, setOpenedSidebarPanel }) {
  const { spreadRef } = useContext(EditorContext);
  const [cellData, setCellData] = useState(null);
  const [error, setError] = useState();
  const [opened, setOpened] = useState(false);
  const initialRender = useRef(true);

  const errorIcon = <i class="fas fa-exclamation-triangle" style={{ color: 'red', border: 'none' }} />;

  const eventTrackingContextData = useContext(EventTrackingContext);
  const { trackEvent } = eventTrackingContextData;

  useEffect(() => {
    const gridPosition = document.getElementsByClassName('spreadWrapper')[0].getBoundingClientRect();
    headerHeight = gridPosition.top;
    leftNavBarWidth = gridPosition.left;
  }, []);

  useEffect(() => {
    const spread = spreadRef?.current;
    const tooltipHandlerWrapper = (event, action) => tooltipHandler(event, action);
    tooltipHandler({ type: 'workpaperLoaded' }, { sheet: spread.getActiveSheet() });

    if (initialRender.current) {
      spread?.bind(GC.Spread.Sheets.Events.EnterCell, tooltipHandlerWrapper);
      spread?.bind(GC.Spread.Sheets.Events.TopRowChanged, tooltipHandlerWrapper);
      spread?.bind(GC.Spread.Sheets.Events.LeftColumnChanged, tooltipHandlerWrapper);
      spread?.bind(GC.Spread.Sheets.Events.ClipboardPasted, tooltipHandlerWrapper);
      spread?.bind(GC.Spread.Sheets.Events.CellChanged, tooltipHandlerWrapper);
      spread?.bind(GC.Spread.Sheets.Events.ActiveSheetChanged, tooltipHandlerWrapper);
    }

    return () => {
      spread?.unbind(GC.Spread.Sheets.Events.EnterCell, tooltipHandlerWrapper);
      spread?.unbind(GC.Spread.Sheets.Events.TopRowChanged, tooltipHandlerWrapper);
      spread?.unbind(GC.Spread.Sheets.Events.LeftColumnChanged, tooltipHandlerWrapper);
      spread?.unbind(GC.Spread.Sheets.Events.ClipboardPasted, tooltipHandlerWrapper);
      spread?.unbind(GC.Spread.Sheets.Events.CellChanged, tooltipHandlerWrapper);
    };
  }, [isLoading]); // eslint-disable-line

  function cell(sheet) {
    const col = sheet.getActiveColumnIndex();
    const row = sheet.getActiveRowIndex();
    const formula = sheet.getFormula(row, col);
    const value = sheet?.getValue(row, col);
    const cellRect = sheet.getCellRect(row, col);
    const x = cellRect.x + cellRect.width + leftNavBarWidth + gavelPositioningGap;
    const y = cellRect.y + cellRect.height + headerHeight + gavelPositioningGap;

    return { formula, value, x, y };
  }

  function handleOutsideClick(event) {
    const clickedElement = event.target;

    // Ignore clicks on the button itself
    if (clickedElement.id === 'wkp-source-tooltip-btn') {
      return;
    }

    // Check if the clicked element is inside the tooltip popover
    if (clickedElement.closest(`.wkp-connection-tooltip-popover`)) {
      return;
    }

    handleTooltipClose();
  }

  function handleClick(type) {
    if (!!error) {
      return;
    }

    if (!opened) {
      const trackingTag = typeToTrackingTagMap[type];
      if (trackingTag) {
        const fakeEvent = getFakeEvent(itemTypes.BUTTON, trackingTag);

        trackEvent(eventNames.interactiveClick, setPropValues(fakeEvent, eventNames.interactiveClick, null));
      }

      document.addEventListener('click', handleOutsideClick);
    }

    setOpened(!opened);
  }

  function handleTooltipClose() {
    setOpened(false);
    document.removeEventListener('click', handleOutsideClick);
  }

  async function tooltipHandler(e, a) {
    setError('');
    setOpened(false);
    let { sheet } = a;

    if (!sheet) {
      sheet = spreadRef.current.getActiveSheet();
    }

    const { formula, value, x, y } = cell(sheet);

    if (!formula || !TooltipToggleHelper.valid(formula, value)) {
      setCellData(null);
    } else {
      console.log('Tooltip Handler triggered by event', e);
      const toggler = createTooltipToggleHelper(sheet, formula);

      if (toggler) {
        const position = { x, y };
        const type = toggler.type;
        setCellData({ loading: true, ...position, type });
        const { data, upcomingRateMsg, errorMessage } = await toggler.fetchData();

        if (errorMessage) {
          setError(errorMessage);
          setCellData({ errorMessage, ...position, type });
        } else {
          setError(null);
          setCellData({
            ...data,
            ...position,
            type,
            upcomingRateMsg,
            loading: false,
          });
          if (!initialRender.current) {
            initialRender.current = true;
          }
        }
      }
    }
  }

  return useMemo(
    () => {
      const { x, y, link, type, tooltipContent, sdcInfo, upcomingRateMsg, loading, errorMessage } = cellData || {};

      const TAX_LAW_TYPES = [STATE_TAX_RATE, INT_TAX_RATE, STATE_APPORTION];
      const mappedType = TAX_LAW_TYPES.includes(type) ? TAX_LAW : type;

      const tooltipConfig = tooltipConfigs[mappedType];

      return (
        <TooltipTogglerPortal className="modal-portal">
          {cellData && tooltipConfig && (
            <div className="wkp-tooltip-toggle-helper" style={{ top: y, left: x }}>
              <BTPopover
                boundary="scrollParent"
                content={tooltipConfig.content({
                  tooltipContent,
                  link,
                  sdcInfo,
                  upcomingRateMsg,
                  setOpenedSidebarPanel,
                  errorMessage,
                  handleTooltipClose,
                })}
                placement="bottom"
                triggerEvent={`click keypress ${opened ? 'mouseover' : ''}`}
                type={!!error ? 'error' : 'trigger'}
              >
                <BTButton
                  btType="icon"
                  disabled={loading}
                  hasSpinner={true}
                  icon={!!error ? errorIcon : tooltipConfig.icon}
                  title={tooltipConfig.title}
                  onClick={() => handleClick(type)}
                />
              </BTPopover>
            </div>
          )}
        </TooltipTogglerPortal>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, cellData, opened]
  );
}

export default TooltipToggler;
