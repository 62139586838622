import { intTaxRateFormulaName } from '../_spreadsheets/formulas/intTaxRatesFormula';
import { sourceDataFormulaName } from '../_spreadsheets/formulas/sourceDataFormula';
import { stateApportionFormulaName } from '../_spreadsheets/formulas/stateApportionFormula';
import { stateTaxRateFormulaName } from '../_spreadsheets/formulas/stateTaxRateFormula';
import GC from '../../../../../SpreadSheets';
import { isSourceDataFormulaMatch } from '../_spreadsheets/formulas';

export class TooltipToggleHelper {
  data;

  /**
   * @public
   * @type {string}
   */
  message;

  /**
   * @public
   * @type {string}
   */
  errorMessage;

  /**
   * @abstract
   * @returns {Promise<void>}
   */
  fetchData() {}

  static invalidValues = null;

  static validTaxFormulaName = new RegExp(
    `(${intTaxRateFormulaName}|${stateApportionFormulaName}|${stateTaxRateFormulaName}|${sourceDataFormulaName})`
  );

  /**
   * @param {string} formula
   * @param {string|object} value
   * @returns {boolean}
   */
  static valid(formula, value) {
    if (!formula) {
      return false;
    }

    if (!this.validTaxFormulaName.test(formula)) {
      return false;
    }

    // Validation should work for NA values on SDC
    if (isSourceDataFormulaMatch(formula)) {
      return true;
    }

    if (!this.invalidValues) {
      const { NotAvailable, Name } = GC.Spread.CalcEngine.Errors;
      this.invalidValues = [NotAvailable, Name].map(JSON.stringify);
    }

    return !this.invalidValues.includes(JSON.stringify(value));
  }
}
