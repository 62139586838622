import * as fetch from '../../../_shared/fetch';
import { dataFlowServiceHostName } from '../../../../configs/params';
import CustomLogger from '../../../_shared/Logger/CustomLogger';

export async function removePendingData(dataFlowId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/pending`;
  const res = await fetch.remove(reqUri);

  return res.json();
}

export async function removeDataFlow(dataFlowId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows`;
  const res = await fetch.remove(reqUri, {
    body: JSON.stringify({ ids: [dataFlowId] }),
  });

  return res.json();
}

export async function getOutputsLocations(dataFlowRunId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/data-flow-run/${dataFlowRunId}/output-locations`;
  const res = await fetch.get(reqUri);
  if (!res.ok) {
    CustomLogger.pushLog(CustomLogger.operations.SAVE_AND_PUBLISH, {
      data_flow_run: dataFlowRunId,
      message: `Error getting output data location from a dataflow run  ${JSON.stringify(res.data)}`,
    });
    throw res.data;
  }
  return res.json();
}

export async function uploadOutputRecords(dataFlowRunId, dataFlowId, pngFile, retrieveOutputLocations) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        const arrayBuffer = reader.result;
        const uint8Array = new Uint8Array(arrayBuffer);

        const response = await fetch.post(
          `${dataFlowServiceHostName}/api/v1/data-flows/data-flow-run/${dataFlowRunId}/upload-output`,
          {
            body: JSON.stringify({ fileData: Array.from(uint8Array) }),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          CustomLogger.pushLog(CustomLogger.operations.PNG_AUDIT_SNAPSHOT_UPLOAD, {
            workflow_id: dataFlowId,
            data_flow_run_id: dataFlowRunId,
            message: `Error posting to the upload-output api endpoint ${JSON.stringify(response.data)}`,
          });
          reject(response.data);
        }

        const data = await response.json();
        resolve(data);
      } catch (error) {
        // Catch error, which might be due to a Gateway timeout; checking if outputLocation was added to the db
        try {
          CustomLogger.pushLog(CustomLogger.operations.SAVE_AND_PUBLISH, {
            workflow_id: dataFlowId,
            data_flow_run_id: dataFlowRunId,
            message: `Api gateway timeout while processing upload-output endpoint or array buffer retrieving output locations from db`,
          });
          const response = await retrieveOutputLocations(dataFlowRunId);
          resolve(response);
        } catch (err) {
          CustomLogger.pushLog(CustomLogger.operations.PNG_AUDIT_SNAPSHOT_UPLOAD, {
            workflow_id: dataFlowId,
            data_flow_run_id: dataFlowRunId,
            message: `POST request catastrophically failed to the upload-output endpoint ${JSON.stringify(error)}`,
          });
          reject(error);
        }
      }
    };
    reader.onerror = () => reject(new Error('PNG snapshot read error during save and publish'));
    reader.readAsArrayBuffer(pngFile);
  });
}
