import { dataFlowServiceHostName } from '../../../../../configs/params';
import * as fetch from '../../../../_shared/fetch';

export async function getDataFlowOutputs({ dataFlowId }) {
  const response = await fetch.get(`${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/outputs`);

  const data = await response.json();

  if (response.status === 404) {
    return {
      deleted: true,
    };
  }

  return {
    deleted: false,
    dataFlow: data,
  };
}
