import { BTAlert, BTButton } from '@btas/jasper';
import { SOURCE_DATA_CONNECTION } from '../../../../_shared/DataReference/ReferenceType';
import dataFlowConnectionSvg from '../SideBar/data_flow_connections_icon.svg';
import data_transformation_editor_icon from '../../../../data-flows/DataFlowsPage/DataFlowsList/Data_transformation_editor_icon.svg';
import output_link_icon from './Output_icon_link.svg';

/**
 * Replace "BOLD" markers in content with <strong> tags
 */
const Format = ({ content }) => {
  const regex = /BOLD(.*?)BOLD/g;
  const formattedContent = content?.replace(regex, '<strong>$1</strong>');
  return <p dangerouslySetInnerHTML={{ __html: formattedContent }} />;
};

export const TAX_LAW = 'TAX_LAW';

// Tooltip configurations
export const tooltipConfigs = {
  [SOURCE_DATA_CONNECTION]: {
    icon: (
      <img
        alt="Data Connect link details"
        className="fas fa-fw"
        id="wkp-source-tooltip-btn"
        src={dataFlowConnectionSvg}
        style={{
          filter: 'brightness(0%) contrast(100%)',
          marginLeft: '2px',
          marginRight: '2px',
          marginTop: '1px',
          marginBottom: '1px',
          maxWidth: '16px',
          height: 'auto',
        }}
      />
    ),
    title: 'Data Connect link details',
    content: ({ sdcInfo, setOpenedSidebarPanel, handleTooltipClose, errorMessage }) => {
      if (errorMessage) {
        return (
          <div className="wkp-connection-tooltip-popover">
            <p>{errorMessage}</p>
          </div>
        );
      }

      const { outputs, name, dataFlowInfo, outputField, parsedFilters, outputId } = sdcInfo || {};
      const { taxPeriod } = dataFlowInfo || {};
      const outputName = outputs?.find(({ id }) => id === outputId)?.name;
      const showFilters = parsedFilters?.length;

      return (
        <div className="wkp-connection-tooltip-popover">
          {
            <>
              <p>
                <img alt="Data Connect name" src={data_transformation_editor_icon} style={{ marginRight: '4px' }} />{' '}
                {taxPeriod}/{name}
              </p>
              <p>
                <img
                  alt="Data Connect output"
                  src={output_link_icon}
                  style={{ marginLeft: '5px', marginRight: '5px' }}
                />{' '}
                {outputName}
              </p>
              <p>
                SUM of '{outputField}' {showFilters ? 'where:' : ''}
              </p>
              {showFilters ? (
                <ul style={{ paddingLeft: '18px' }}>
                  {parsedFilters?.map(({ field, value }, index) => (
                    <li key={`source-data-tooltip-filter-${index}`}>
                      '{field}' = '{value}'
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}
              <div styles={{ marginLeft: 0 }}>
                <BTButton
                  btStyle="link"
                  id="wkp-connection-tooltip-manage-data-transformation-btn"
                  onClick={() => {
                    setOpenedSidebarPanel('sourceData');
                    handleTooltipClose();
                  }}
                >
                  <span>Manage Data Connect Link</span>
                </BTButton>
              </div>
            </>
          }
        </div>
      );
    },
  },
  [TAX_LAW]: {
    icon: <i className="fas fa-gavel fa-fw" />,
    title: 'Related analysis',
    content: ({ tooltipContent, link, upcomingRateMsg, errorMessage }) => {
      if (errorMessage) {
        return (
          <div className="wkp-tooltip-toggle-popover">
            <p>{errorMessage}</p>
          </div>
        );
      }

      return (
        <div className="wkp-tooltip-toggle-popover">
          <Format content={tooltipContent} />
          <a href={link} rel="noreferrer" target="_blank">
            <strong>View related analysis in Bloomberg Tax Research</strong>
            <i className="fas fa-external-link-alt fa-fw" />
          </a>
          {upcomingRateMsg && <BTAlert btStyle="info">{upcomingRateMsg}</BTAlert>}
        </div>
      );
    },
  },
  // Additional configurations can be added here in the future
};
