import { getUserId } from '../../_shared/auth';

function getSelectionsUserCanDeleteFactory(selfPermission, othersPermission) {
  return function (selectedItems, userPermissions) {
    const userId = getUserId();
    const idsToDelete = selectedItems.map(sel => sel.id);

    const deleteSet = new Set(idsToDelete);

    const itemsToDelete = selectedItems.filter(item => {
      if (!userPermissions.includes(selfPermission)) {
        return false;
      }
      if (!userPermissions.includes(othersPermission)) {
        return item.userId === userId && deleteSet.has(item.id);
      }
      return true;
    });

    return new Map(itemsToDelete.map(i => [i.id, i]));
  };
}

export const getWorkflowsUserCanDelete = getSelectionsUserCanDeleteFactory(
  'delete_workflow_self',
  'delete_workflow_others'
);
export const getWorkpapersUserCanDelete = getSelectionsUserCanDeleteFactory(
  'delete_workpaper_self',
  'delete_workpaper_others'
);
