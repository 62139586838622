import React, { useEffect, useState } from 'react';
import { BTAlert } from '@btas/jasper';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Spinner from '../../_shared/Spinner';
import Grid from './SavedWorkpapers/Grid';
import './SavedWorkpapers/styles.scss';
import useFiles from './useFiles';
import FeedBackDataflowModal from '../common/FeedbackDataFlowModal';
import CustomLogger from '../../_shared/Logger/CustomLogger';

export default function SavedWorkpapers({
  actions,
  config,
  isSortingFromGrid,
  isTaxPeriodSelected,
  setHasDeleteWfError,
  setIsSortingFromGrid,
  setUndeletedWorkpapers,
  cleanGridSelection,
  loadJobs,
  processes,
}) {
  const { workpapersDropzone, uploadError, setUploadError } = useFiles({
    loadJobs,
  });

  const { getRootProps, getInputProps, isDragActive, open } = workpapersDropzone;
  const { isLoading, gridData } = config;
  const { setFilter, setGridSorting, clearGridFilters } = actions;

  const [alertError, setAlertError] = useState(false);
  const [showFeedbackDataFlowModal, setShowFeedbackDataFlowModal] = useState(false);
  const [invalidTypeUploadFileNames, setInvalidTypeUploadFileNames] = useState('');

  const showEmptyState = gridData.totalCount === 0;
  const sectionClassName = `wkp-saved-wkp-section${isDragActive ? ' wkp-drag' : ''}`;

  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  useEffect(() => {
    if (!isLoading && startTime) {
      CustomLogger.pushLog(CustomLogger.operations.SPREADSHEETS_LANDING_PAGE, {
        message: `Spreadsheet landing page loaded in ${(Date.now() - startTime).toString()} ms`,
        duration: (Date.now() - startTime).toString(),
      });
    }
  }, [isLoading, startTime]);

  useEffect(() => {
    if (workpapersDropzone.fileRejections.length > 0) {
      let fileNames = [];
      workpapersDropzone.fileRejections.map(item => {
        if (item != null && item.file != null && item.file.size > process.env.REACT_APP_MAX_FILE_SIZE * 1024 * 1024) {
          setShowFeedbackDataFlowModal(true);
        } else if (item != null && item.file != null && (item.file.name !== 'xlsx' || item.file.name !== 'csv')) {
          fileNames.push(item.file.name);
        }
        return item.fileName;
      });
      setInvalidTypeUploadFileNames(fileNames.join(', '));
    }
  }, [workpapersDropzone.fileRejections]);

  const handleErrorDismiss = () => {
    setUploadError(false);
    setInvalidTypeUploadFileNames('');
  };
  const handleFilterChange = (filter, value) => setFilter(filter, value);
  const handleSortChange = order => setGridSorting(order);

  return (
    <Row>
      <Col>
        <div className={sectionClassName}>
          {!showEmptyState && <h2>Saved workpapers</h2>}
          <div className="wkp-grid-container" {...getRootProps()}>
            {invalidTypeUploadFileNames && (
              <BTAlert appear dismissible fixed btStyle="danger" onDismiss={handleErrorDismiss}>
                Failed upload(s) for the following file(s): <b>{invalidTypeUploadFileNames}</b>. Invalid file format.
                Source file(s) must be of type *.xlsx or *.csv, and must be extracted out of a compressed folder before
                uploading.
              </BTAlert>
            )}
            {showFeedbackDataFlowModal && (
              <FeedBackDataflowModal onCancelClick={() => setShowFeedbackDataFlowModal(false)} />
            )}
            {uploadError && (
              <BTAlert appear dismissible fixed btStyle="danger" onDismiss={() => setUploadError(false)}>
                There was an error uploading your file, please try again or contact the administrator.
              </BTAlert>
            )}
            {alertError && (
              <BTAlert appear dismissible fixed btStyle="danger" onDismiss={() => setAlertError(false)}>
                {alertError}
              </BTAlert>
            )}
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <Grid
                  actions={actions}
                  cleanGridSelection={cleanGridSelection}
                  config={config}
                  isDragActive={isDragActive}
                  isSortingFromGrid={isSortingFromGrid}
                  isTaxPeriodSelected={isTaxPeriodSelected}
                  loadJobs={loadJobs}
                  open={open}
                  processes={processes}
                  setHasDeleteWfError={setHasDeleteWfError}
                  setIsSortingFromGrid={setIsSortingFromGrid}
                  setUndeletedWorkpapers={setUndeletedWorkpapers}
                  workpapersDropzone={workpapersDropzone}
                  onClearFilters={clearGridFilters}
                  onFilterChange={handleFilterChange}
                  onSortChange={handleSortChange}
                />
                <input {...getInputProps()} />
              </>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}
