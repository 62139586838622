import { CELL_REVIEW, DATA_LINK } from '../../../_shared/DataReference/ReferenceType';
import { CommandType } from '../../../_shared/commandType';
import { BroadcastChannels, EventTypes } from '../useBroadcast/constants';
import { cleanDirtyCellValue, setCellTag } from './dataReferenceHelper';
import { v4 as uuidv4 } from 'uuid';
export default function useCellTracker(
  spreadRef,
  workpaperIdRef,
  dataReferences,
  dataReferencePositionUpdateQueue,
  dataReferenceDeleteQueue,
  dataReferenceCellTagUpdateQueue,
  lastCellReviewActionTimestamp,
  deleteCellReviewCommand = null,
  dataReferenceHistoryTracker,
  processDataReferencePositionQueue,
  processDataReferencesDeleteQueue,
  enqueueManyDataReferences,
  processCellReviewQueue,
  sendMessage
) {
  const { addToHistory } = dataReferenceHistoryTracker?.current;
  let updateCellReferencesTags = [];
  const formulas = ['SOURCE_DATA', 'INTTAXRATE', 'STATETAXRATE', 'STATEAPPORTION'];
  function trackPosition(command, sheet) {
    if (dataReferences.current) {
      deleteDataReferences(command);
      trackSheetNameUpdate(command);
      const sheetReferences = dataReferences?.current?.filter(
        r => r.sheetName === command?.sheetName && r.workpaperId === workpaperIdRef.current
      );
      if (sheetReferences && sheetReferences.length > 0) {
        let referencesToUpdate = sheetReferences;
        const isUndo = command.actionType === 1;
        const { cmd, selections, isRow } = command;
        if (
          selections &&
          selections.length > 0 &&
          command.cmd !== CommandType.DragDrop &&
          command.cmd !== CommandType.DragFill &&
          command.cmd !== CommandType.ClearCellValue
        ) {
          for (let i = 0; i < selections.length; i++) {
            let { row, col, rowCount, colCount } = selections[i];
            row = row < 0 ? 0 : row;
            col = col < 0 ? 0 : col;
            switch (cmd) {
              case CommandType.InsertEntireRow:
              case CommandType.InsertPartialRow:
              case CommandType.InsertCellsDown:
              case CommandType.DeleteEntireRow:
              case CommandType.DeleteCellsUp:
              case CommandType.InsertRowOrColumn && isRow:
              case CommandType.DeleteEntireRowColumn && isRow:
                let rowPositionCount = rowCount;
                let rowFilter = 'row';
                const rowType = 'row';
                let rowOperation = 'greater-equal';
                if (cmd === CommandType.DeleteEntireRow || (cmd === CommandType.DeleteEntireRowColumn && isRow)) {
                  rowPositionCount = -rowPositionCount;
                }
                if (cmd === CommandType.DeleteCellsUp) {
                  rowPositionCount = -rowPositionCount;
                  rowFilter = 'column';
                  rowOperation = 'equal';
                }
                if (cmd === CommandType.InsertCellsDown) {
                  rowFilter = 'column';
                  rowOperation = 'equal';
                }
                if (isUndo) {
                  rowPositionCount = rowPositionCount * -1;
                  if (rowOperation === 'equal') {
                    rowOperation = 'greater-equal';
                  }
                }
                referencesToUpdate = UpdateCellPositions(
                  referencesToUpdate,
                  rowType,
                  rowFilter,
                  rowOperation,
                  row,
                  col,
                  rowPositionCount
                );
                updateCellReferencesTags = [...updateCellReferencesTags, ...referencesToUpdate];
                break;

              case CommandType.InsertEntireColumn:
              case CommandType.InsertCellsRight:
              case CommandType.DeleteEntireColumn:
              case CommandType.DeleteCellsLeft:
              case CommandType.InsertRowOrColumn && !isRow:
              case CommandType.DeleteEntireRowColumn && !isRow:
                let columnPositionCount = colCount;
                let colFilter = 'column';
                const colType = 'column';
                let colOperation = 'greater-equal';
                if (cmd === CommandType.DeleteEntireColumn || (cmd === CommandType.DeleteEntireRowColumn && !isRow)) {
                  columnPositionCount = -columnPositionCount;
                }
                if (cmd === CommandType.DeleteCellsLeft) {
                  columnPositionCount = -columnPositionCount;
                  colFilter = 'row';
                  colOperation = 'equal';
                }
                if (cmd === CommandType.InsertCellsRight) {
                  colFilter = 'row';
                  colOperation = 'equal';
                }
                if (isUndo) {
                  columnPositionCount = columnPositionCount * -1;
                  if (colOperation === 'equal') {
                    colOperation = 'greater-equal';
                  }
                }
                referencesToUpdate = UpdateCellPositions(
                  referencesToUpdate,
                  colType,
                  colFilter,
                  colOperation,
                  row,
                  col,
                  columnPositionCount
                );
                updateCellReferencesTags = [...updateCellReferencesTags, ...referencesToUpdate];
                break;

              default:
                referencesToUpdate = [];
                break;
            }
          }
        } else if (command.cmd === CommandType.DragDrop) {
          const valueReferences = [];
          const tagReferences = [];
          const isUndo = command.actionType === 1;
          const isUndMultiplier = isUndo ? -1 : 1;
          const rowColumnReferences = [];
          const rowCount = command.toRow - command.fromRow;
          const columnCount = command.toColumn - command.fromColumn;
          var selectedRanges = sheet.getSelections();
          for (let i = 0; i < selectedRanges.length; i++) {
            const selectedRange = selectedRanges[i];
            for (let r = 0; r < selectedRange.rowCount; r++) {
              for (let c = 0; c < selectedRange.colCount; c++) {
                const selectedRangeRow = (isUndo ? command.toRow : command.fromRow) + r;
                const selectedRangeCol = (isUndo ? command.toColumn : command.fromColumn) + c;
                const targetRangeRow = (isUndo ? command.fromRow : command.toRow) + r;
                const targetRangeCol = (isUndo ? command.fromColumn : command.toColumn) + c;
                const references = sheetReferences.filter(
                  reference => reference.column === selectedRangeCol && reference.row === selectedRangeRow
                );
                trackCellReviewPastedReferences(
                  valueReferences,
                  tagReferences,
                  command.toRow + r,
                  command.toColumn + c,
                  sheet.name(),
                  sheet
                );
                if (references?.length) {
                  if (!isUndo || !valueReferences.filter(x => references.filter(y => y.id === x.id)).length)
                    rowColumnReferences.push(...references);
                  tagReferences.push(
                    ...references.map(rowColumnReference => {
                      return {
                        row: targetRangeRow,
                        column: targetRangeCol,
                        value: rowColumnReference.value,
                        id: rowColumnReference.id,
                        sheetName: sheet.name(),
                      };
                    })
                  );
                }
              }
            }
          }
          updateCellReferencesTags.push(...tagReferences);
          if (valueReferences.length) {
            UpdateDataReferences(valueReferences);
          }

          const newRowCount = rowCount * isUndMultiplier;
          const newColCount = columnCount * isUndMultiplier;
          referencesToUpdate = UpdateCellPositions(rowColumnReferences, 'rowcolumn', null, null, null, null, {
            rowCount: newRowCount,
            columnCount: newColCount,
          });
        } else if (command.cmd === CommandType.DragFill) {
          referencesToUpdate = [];
          const valueReferences = [];
          const tagReferences = [];
          const selectedRanges = sheet.getSelections();
          const isUndo = command.actionType === 1;
          let { row, col, rowCount, colCount } = selectedRanges[0];
          for (let r = 0; r < (isUndo ? command.fillRange.rowCount : rowCount); r++) {
            for (let c = 0; c < (isUndo ? command.fillRange.colCount : colCount); c++) {
              const selectedRangeRow = (isUndo ? command.fillRange.row : row) + r;
              const selectedRangeCol = (isUndo ? command.fillRange.col : col) + c;
              const references = sheetReferences.filter(
                reference =>
                  reference.column === selectedRangeCol &&
                  reference.row === selectedRangeRow &&
                  reference.type === CELL_REVIEW
              );
              if (references?.length) {
                references.forEach(reference => {
                  const value = cleanDirtyCellValue(sheet.getCell(reference.row, reference.column).text());
                  if (reference.value !== value && !valueReferences.find(x => x.id === reference.id)) {
                    tagReferences.push({
                      row: reference.row,
                      column: reference.column,
                      value: value,
                      id: reference.id,
                      sheetName: sheet.name(),
                    });
                    valueReferences.push({ ...reference, value: value });
                  }
                });
              }
            }
          }

          if (valueReferences.length) {
            updateCellReferencesTags.push(...tagReferences);
            referencesToUpdate = [...referencesToUpdate, ...valueReferences];
          }
        } else if (command.cmd === CommandType.ClearCellValue) {
          referencesToUpdate = [];
          const valueReferences = [];
          const referenceIdsToDelete = [];
          for (let i = 0; i < command.ranges.length; i++) {
            const selectedRange = command.ranges[i];
            let { row, col, rowCount, colCount } = selectedRange;
            row = row < 0 ? 0 : row;
            col = col < 0 ? 0 : col;
            for (let r = 0; r < rowCount; r++) {
              for (let c = 0; c < colCount; c++) {
                const selectedRangeRow = row + r;
                const selectedRangeCol = col + c;
                const idsToDelete = updateReferencesFromCells(
                  sheetReferences,
                  sheet,
                  selectedRangeRow,
                  selectedRangeCol,
                  valueReferences
                );
                if (idsToDelete?.length) {
                  referenceIdsToDelete.push(...idsToDelete);
                }
              }
            }
          }
          referencesToUpdate = [...referencesToUpdate, ...valueReferences];
          if (referenceIdsToDelete.length > 0) {
            dataReferenceDeleteQueue.current = dataReferenceDeleteQueue.current.concat(referenceIdsToDelete);
            processDataReferencesDeleteQueue(
              workpaperIdRef.current,
              undefined,
              deleteCellReviewCommand?.current?.length,
              spreadRef?.current
            );
            enqueueCellTagUpdateQueue(updateCellReferencesTags);
          }
        } else {
          referencesToUpdate = [];
        }

        if (Object.values(CommandType).indexOf(command.cmd) > -1 && command.cmd !== CommandType.CellPasting) {
          UpdateDataReferences(referencesToUpdate);
        }
      }
    }
    if (command.cmd !== 'Designer.setTag' && !process.env.REACT_APP_NODE_IGNORE_DB_UPDATE) {
      updateSheetReferencesFromCells(sheet, command.sheetNames);
    }
  }

  function updateSheetReferencesFromCells(sheet, sheetNames) {
    const valueReferences = [];
    if (sheetNames && sheetNames?.length) {
      for (let i = 0; i < sheetNames.length; i++) {
        const cellReviewReferences = [];
        const sheetName = sheetNames[i];
        const currentSheet = sheet?.getParent()?.getSheetFromName(sheetName);
        const referenceDependentCells = currentSheet?.getDirtyCells();
        const sheetCellReviewReferences = dataReferences?.current?.filter(
          r => r.sheetName === sheetName && r.type === CELL_REVIEW
        );
        referenceDependentCells?.length &&
          referenceDependentCells.forEach(x => {
            cellReviewReferences.push(...sheetCellReviewReferences?.filter(y => y.row === x.row && y.column === x.col));
          });
        cellReviewReferences?.length &&
          cellReviewReferences.forEach(x => {
            const cellValue = cleanDirtyCellValue(currentSheet.getCell(x.row, x.column).text());
            if (x && x.value !== cellValue && !valueReferences.find(y => y.id === x.id)) {
              valueReferences.push({ ...x, value: cellValue });
            }
          });
      }
    }
    if (valueReferences.length) {
      UpdateDataReferences(valueReferences);
      enqueueCellTagUpdateQueue(valueReferences);
    }
  }

  function updateReferencesFromCells(sheetReferences, sheet, row, col, valueReferences) {
    const cellReferences = sheetReferences.filter(x => x.row === row && x.column === col);
    if (cellReferences?.length) {
      const cellReviewReferences = cellReferences.filter(x => x.type === CELL_REVIEW);
      const notCellReviewReferences = cellReferences.filter(x => x.type !== CELL_REVIEW);
      let cellTag = null;
      if (cellReviewReferences?.length) {
        const value = sheet.getCell(row, col).text();
        const cellValue = cleanDirtyCellValue(value);
        cellTag = {
          value: cellValue,
          references: cellReviewReferences.map(x => {
            valueReferences.push({ ...x, value: cellValue });
            return { type: x.type, id: x.id };
          }),
        };
      } else {
        cellTag = null;
      }
      setCellTag(sheet, row, col, cellTag);
      updateCellReferencesTags.push({
        row: row,
        column: col,
        value: null,
        sheetName: sheet.name(),
      });
      if (notCellReviewReferences?.length) {
        return notCellReviewReferences.map(x => x.id);
      }

      return null;
    }
  }

  function trackMetadataUpdate(workpaperId, metadata) {
    const { newTaxPeriod, name } = metadata;
    const referencesToUpdate = dataReferences?.current?.reduce((toUpdate, r) => {
      if (r.type === DATA_LINK) {
        const params = typeof r.parameters === 'string' ? JSON.parse(r.parameters) : r.parameters;
        if (params.workpaperId === workpaperId) {
          if (params.taxPeriod !== newTaxPeriod || params.workpaperName !== name) {
            params.taxPeriod = newTaxPeriod;
            params.workpaperName = name;
            r.parameters = params;
            toUpdate.push(r);
          }
        }
      }
      return toUpdate;
    }, []);

    if (referencesToUpdate.length) {
      sendMessage(BroadcastChannels.SourceUpdateReferences, {
        type: EventTypes.DataLinkSourceUpdated,
        details: referencesToUpdate,
      });
      UpdateDataReferences(referencesToUpdate);
    }
  }

  function isRenameSheetCommand(command) {
    return command.cmd === CommandType.RenameSheet;
  }

  function trackSheetNameUpdate(command) {
    if (!isRenameSheetCommand(command)) return;

    const { name: newName, oldName } = command;
    const referencesToUpdate = dataReferences.current.reduce((toUpdate, r) => {
      if (r.type === DATA_LINK) {
        const params = typeof r.parameters === 'string' ? JSON.parse(r.parameters) : r.parameters;
        if (params.workpaperId === workpaperIdRef.current) {
          if (params.sheetName === oldName) {
            params.sheetName = newName;
            r.parameters = params;
            toUpdate.push(r);
          }
        }
      }
      return toUpdate;
    }, []);

    if (referencesToUpdate.length) {
      sendMessage(BroadcastChannels.SourceUpdateReferences, {
        type: EventTypes.DataLinkSourceUpdated,
        details: referencesToUpdate,
      });
      UpdateDataReferences(referencesToUpdate);
    }
  }

  function trackCellReviewPastedReferences(valueReferences, tagReferences, row, col, fromSheet, sheet) {
    const cellReviewsReferences = dataReferences.current.filter(
      reference =>
        reference.column === col &&
        reference.row === row &&
        reference.sheetName === fromSheet &&
        reference.type === CELL_REVIEW
    );
    if (cellReviewsReferences?.length) {
      cellReviewsReferences.forEach(reference => {
        const value = cleanDirtyCellValue(sheet.getCell(reference.row, reference.column).text());
        if (reference.value !== value && !valueReferences.find(x => x.id === reference.id)) {
          tagReferences.push({
            row: reference.row,
            column: reference.column,
            value: value,
            id: reference.id,
            sheetName: sheet.name(),
          });
          valueReferences.push({ ...reference, value: value });
        }
      });
    }
  }

  function trackPastedReference(eventInfo) {
    if (eventInfo) {
      const cutPastedReferences = [];
      const cutPastedCellReviewReferences = [];
      const referencesToDelete = [];
      const valueReferences = [];
      const tagReferences = [];
      let targetSheetParent;
      if (eventInfo?.fromRange && eventInfo?.cellRange) {
        const fromRange = eventInfo.fromRange;
        const pastedRange = eventInfo.cellRange;
        let pastedRangeRowCount = pastedRange.rowCount;
        let pastedRangeColCount = pastedRange.colCount;
        let pastedRangeRow = pastedRange.row;
        let pastedRangeCol = pastedRange.col;
        const fromSheet = eventInfo.fromSheet.name();
        const targetSheet = eventInfo.sheet.name();
        targetSheetParent = eventInfo.sheet;
        for (let r = 0; r < pastedRangeRowCount; r++) {
          for (let c = 0; c < pastedRangeColCount; c++) {
            const selectedFromRangeRow = fromRange.row + r;
            const selectedFromRangeCol = fromRange.col + c;
            const selectedToRangeRow = pastedRange.row + r;
            const selectedToRangeCol = pastedRange.col + c;
            const selectedReferences = dataReferences.current.filter(
              reference =>
                reference.column === selectedFromRangeCol &&
                reference.row === selectedFromRangeRow &&
                reference.sheetName === fromSheet
            );
            if (eventInfo?.isCutting) {
              if (selectedReferences?.length) {
                if (fromSheet === targetSheet) {
                  cutPastedReferences.push(...selectedReferences);
                  updateCellReferencesTags.push(...selectedReferences);
                } else {
                  const cellReviewsReferences = selectedReferences.filter(x => x.type === CELL_REVIEW);
                  if (cellReviewsReferences?.length) {
                    const newCellReviewsReferences = cellReviewsReferences.map(reference => {
                      return {
                        ...reference,
                        sheetName: targetSheet,
                        id: uuidv4(),
                        row: selectedToRangeRow,
                        column: selectedToRangeCol,
                      };
                    });
                    cutPastedCellReviewReferences.push(...newCellReviewsReferences);
                  }
                  referencesToDelete.push(...selectedReferences.map(x => x.id));
                }
              }
            }
            trackCellReviewPastedReferences(
              valueReferences,
              tagReferences,
              selectedToRangeRow,
              selectedToRangeCol,
              fromSheet,
              targetSheetParent
            );
          }
        }

        if (valueReferences.length) {
          updateCellReferencesTags.push(...tagReferences);
          UpdateDataReferences(valueReferences);
        }

        if (cutPastedReferences.length > 0) {
          const rowCount = pastedRangeRow - fromRange.row;
          const columnCount = pastedRangeCol - fromRange.col;
          const referencesToUpdate = UpdateCellPositions(cutPastedReferences, 'rowcolumn', null, null, null, null, {
            rowCount,
            columnCount,
          });
          UpdateDataReferences(referencesToUpdate);
        }
        if (cutPastedCellReviewReferences.length) {
          enqueueManyDataReferences(cutPastedCellReviewReferences);
          processCellReviewQueue(targetSheetParent.parent, targetSheetParent, addToHistory, false);
          updateCellReferencesTags.push(...cutPastedCellReviewReferences);
        }
        if (referencesToDelete.length > 0) {
          const referenceIds = dataReferenceDeleteQueue.current.concat(referencesToDelete);
          dataReferences.current = dataReferences.current.filter(x => !referenceIds.some(y => y === x.id));
          dataReferenceDeleteQueue.current = referenceIds;
          processDataReferencesDeleteQueue(
            workpaperIdRef.current,
            undefined,
            deleteCellReviewCommand?.current?.length,
            spreadRef?.current
          );
        }
      }
    }
  }

  function clearReferenceCell(eventInfo) {
    if (eventInfo) {
      const sheet = eventInfo.sheet;
      const formula = sheet.getFormula(eventInfo.row, eventInfo.col);
      const valueReferences = [];
      let hasCustomFormula = false;
      if (formula) {
        hasCustomFormula = formulas.some(frml => formula.includes(frml));
      }
      if (!eventInfo.newValue || !hasCustomFormula) {
        const sheetReferences = dataReferences?.current?.filter(r => r.sheetName === sheet.name());
        const referenceIdsToDelete = updateReferencesFromCells(
          sheetReferences,
          sheet,
          eventInfo.row,
          eventInfo.col,
          valueReferences
        );
        if (valueReferences.length) {
          UpdateDataReferences(valueReferences);
        }
        if (referenceIdsToDelete?.length) {
          const referenceIds = dataReferenceDeleteQueue.current.concat(referenceIdsToDelete);
          dataReferences.current = dataReferences.current.filter(x => !referenceIds.some(y => y === x.id));
          dataReferenceDeleteQueue.current = referenceIds;
          processDataReferencesDeleteQueue(
            workpaperIdRef.current,
            undefined,
            deleteCellReviewCommand?.current?.length,
            spreadRef?.current
          );
        }
        enqueueCellTagUpdateQueue(updateCellReferencesTags);
      }

      if (eventInfo.newValue && eventInfo.newValue !== eventInfo.oldValue) {
        const cellReviewsReferences = dataReferences.current.filter(
          x =>
            x.type === CELL_REVIEW &&
            x.row === eventInfo.row &&
            x.column === eventInfo.col &&
            x.sheetName === sheet.name()
        );
        if (cellReviewsReferences?.length) {
          const newCellReviewsReferences = cellReviewsReferences.map(reference => {
            return {
              ...reference,
              value: eventInfo.newValue,
              newValue: eventInfo.newValue,
            };
          });
          dataReferencePositionUpdateQueue.current = [
            ...dataReferencePositionUpdateQueue.current,
            ...newCellReviewsReferences,
          ];
          processDataReferencePositionQueue();
        }
      }
    }
  }

  function UpdateCellPositions(positionReferences, type, filter, operation, row, column, count) {
    const referencesPositionsToUpdate = [];
    positionReferences.forEach(reference => {
      let filterPosition;
      let position;
      let isWithinSelection = false;
      let isWithinRange = false;
      if (filter === 'row') {
        filterPosition = reference.row;
        position = row;
        isWithinRange = reference.column >= column;
      } else if (filter === 'column') {
        filterPosition = reference.column;
        position = column;
        isWithinRange = reference.row >= row;
      }
      if (operation === 'equal') {
        isWithinSelection = filterPosition === position;
      } else if (operation === 'greater-equal') {
        isWithinSelection = filterPosition >= position;
      }
      if (type === 'row' && isWithinSelection === true && isWithinRange === true) {
        const updatedRowCount = reference.row + count;
        if (updatedRowCount >= 0) {
          return referencesPositionsToUpdate.push({ ...reference, row: updatedRowCount });
        }
      } else if (type === 'column' && isWithinSelection === true && isWithinRange === true) {
        const updatedColumnCount = reference.column + count;
        if (updatedColumnCount >= 0) {
          return referencesPositionsToUpdate.push({ ...reference, column: updatedColumnCount });
        }
      } else if (type === 'rowcolumn') {
        return referencesPositionsToUpdate.push({
          ...reference,
          column: reference.column + count.columnCount,
          row: reference.row + count.rowCount,
        });
      }
    });
    return referencesPositionsToUpdate;
  }

  function UpdateDataReferences(referencesToUpdate) {
    dataReferencePositionUpdateQueue.current = [...dataReferencePositionUpdateQueue.current, ...referencesToUpdate];
    processDataReferencePositionQueue();
    if (referencesToUpdate && referencesToUpdate.length > 0) {
      const updatedInMemoryReference = [];
      dataReferences.current.forEach(reference => {
        const updatedReference = referencesToUpdate.find(x => x.id === reference.id);
        if (updatedReference) {
          const isDataLinkSource = updatedReference.workpaperId !== workpaperIdRef.current;
          const hasChanges =
            updatedReference.row !== reference.row ||
            updatedReference.column !== reference.column ||
            updatedReference.value !== reference.value;
          const isCellReview = updatedReference.type === CELL_REVIEW;

          if (hasChanges || isDataLinkSource) {
            updatedInMemoryReference.push(updatedReference);
          }
          if (isCellReview) {
            lastCellReviewActionTimestamp.current = Date.now();
          }
        } else {
          updatedInMemoryReference.push(reference);
        }
      });
      dataReferences.current = updatedInMemoryReference;
      if (updateCellReferencesTags && updateCellReferencesTags.length > 0) {
        enqueueCellTagUpdateQueue(updateCellReferencesTags);
      }
    }
  }

  function isDeleteCommand(command) {
    const { cmd, actionType } = command;
    if (actionType === 0) {
      return [
        CommandType.DragDrop,
        CommandType.DeleteEntireRow,
        CommandType.DeleteCellsUp,
        CommandType.DeleteEntireColumn,
        CommandType.DeleteCellsLeft,
      ].includes(cmd);
    } else if (actionType === 1) {
      return [CommandType.CellPasting, CommandType.DragFill].includes(cmd);
    }
  }

  function getSelection(command) {
    const { cmd, selections } = command;
    if (cmd === CommandType.DragFill) {
      return [command.fillRange];
    }

    return selections;
  }

  function deleteDataReferences(command) {
    if (!isDeleteCommand(command)) return;

    const { sheetName, actionType } = command;
    const isUndo = actionType === 1;
    const sheetReferences = dataReferences.current.filter(r => r.sheetName === sheetName);
    let referenceIds = [];
    if (sheetReferences?.length) {
      const selections = getSelection(command);
      if (selections && selections.length > 0) {
        for (let i = 0; i < selections.length; i++) {
          let { row, col, rowCount, colCount } = selections[i];
          row = row < 0 ? 0 : row;
          col = col < 0 ? 0 : col;
          for (let r = 0; r < rowCount; r++) {
            for (let c = 0; c < colCount; c++) {
              const selectedRangeRow = row + r;
              const selectedRangeCol = col + c;

              if (isUndo) {
                if (spreadRef?.current) {
                  const formula = spreadRef.current
                    .getSheetFromName(sheetName)
                    .getFormula(selectedRangeRow, selectedRangeCol);

                  if (formula && formulas.some(frml => formula.includes(frml))) {
                    continue;
                  }
                }
              }

              const referencesToDelete = sheetReferences.filter(
                ({ row, column }) => row === selectedRangeRow && column === selectedRangeCol
              );
              if (referencesToDelete?.length) {
                const cellReviewRowReferences = referencesToDelete.filter(x => x.type === CELL_REVIEW);
                if (cellReviewRowReferences?.length) deleteCellReviewCommand.current.push(...cellReviewRowReferences);
                referenceIds.push(...referencesToDelete.map(x => x.id));
              }
            }
          }
        }
        if (referenceIds.length) {
          dataReferenceDeleteQueue.current = [...dataReferenceDeleteQueue.current, ...referenceIds];
          dataReferences.current = dataReferences.current.filter(x => !referenceIds.some(y => y === x.id));
          processDataReferencesDeleteQueue(
            workpaperIdRef.current,
            undefined,
            deleteCellReviewCommand?.current?.length,
            spreadRef?.current
          );
        }
      }
    }
  }

  function enqueueCellTagUpdateQueue(referencesToUpdate) {
    updateCellReferencesTags = [...updateCellReferencesTags, ...referencesToUpdate];
    if (updateCellReferencesTags.length) {
      const uniqueResetTags = [...dataReferenceCellTagUpdateQueue.current, ...updateCellReferencesTags];
      dataReferenceCellTagUpdateQueue.current = uniqueResetTags.filter((uniqueResetTag, index) => {
        return (
          index ===
          uniqueResetTags.findIndex(
            x =>
              uniqueResetTag.row === x.row &&
              uniqueResetTag.column === x.column &&
              uniqueResetTag.sheetName === x.sheetName
          )
        );
      });
      updateCellReferencesTags = [];
    }
  }
  return {
    trackPosition,
    trackPastedReference,
    trackMetadataUpdate,
    clearReferenceCell,
    dataReferences,
    dataReferencePositionUpdateQueue,
    dataReferenceDeleteQueue,
    dataReferenceCellTagUpdateQueue,
  };
}
