import { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { getImportPresignedUrl, keepFiles, replaceFiles, runSjsUpload, uploadFileToBucket } from './apis';

import { startJob } from '../../_shared/jobs/apis';
import { WORKPAPER_IMPORT_JOB_TYPE } from '../../_shared/jobs/jobTypes';
import useJobsOverlay from '../common/JobsOverlayWindow/useJobsOverlay';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import { isEmptyObject } from '../../editor/EditorPage/DataReference/dataReferenceHelper';
import UserPermissionsContext from '../../_shared/UserPermissionsContext';

const upload =
  fn =>
  ({ file, fileType, setUploadFiles }) => {
    fn({
      file,
      fileType,
      progress({ percentComplete }) {
        setUploadFiles(prev => ({
          ...prev,
          [file.path]: { ...prev[file.path], progress: percentComplete, failed: false, errors: undefined },
        }));
      },
      success({ statusCode, response, error }) {
        if (statusCode === 200) {
          setUploadFiles(prev => ({
            ...prev,
            [file.path]: { ...prev[file.path], progress: 100, id: response.id, name: response.name, success: true },
          }));
        } else {
          setUploadFiles(prev => ({
            ...prev,
            [file.path]: {
              ...prev[file.path],
              progress: 0,
              failed: true,
              errors: response ? response.error.details : [error],
            },
          }));
        }
      },
      failure({ error }) {
        setUploadFiles(prev => ({
          ...prev,
          [file.path]: { ...prev[file.path], progress: 0, failed: true, errors: [error] },
        }));
      },
    });
  };

const uploadToBucket = async (file, loadJobs, bindOnJobCompleted) => {
  const workpaperId = uuidv4();
  const [fileName, fileSize] = [file.name, file.size];

  const { jobId } = await startJob({
    entityId: workpaperId,
    jobType: WORKPAPER_IMPORT_JOB_TYPE,
    payload: {
      fileName,
    },
  });

  bindOnJobCompleted(
    jobId,
    function (startTime) {
      CustomLogger.pushLog(CustomLogger.operations.UPLOAD, {
        workpaperId,
        duration: (Date.now() - startTime).toString(),
        fileName,
        fileSize: fileSize.toString(),
      });
    },
    Date.now()
  );

  await loadJobs();

  setTimeout(async () => {
    await uploadWorkbookFile({ file, workpaperId, jobId });
    await loadJobs();
  });
};

const uploadWorkbookFile = async ({ file, workpaperId, jobId }) => {
  const [fileName, fileSize] = [file.name, file.size];

  try {
    const { url, key } = await getImportPresignedUrl(fileName, workpaperId);
    await uploadFileToBucket(url, file);

    await runSjsUpload({
      workpaperId,
      jobId,
      fileName,
      key,
    });
  } catch (error) {
    if (!isEmptyObject(error)) {
      CustomLogger.pushLog(CustomLogger.operations.UPLOAD, {
        error: JSON.stringify(error),
        workpaperId,
        fileName,
        fileSize,
      });
    }
  }
};

export default function useFiles({ onUploadCallback, loadJobs }) {
  const onUploadCallbackFn = onUploadCallback || (() => {});
  const [uploadFiles, setUploadFiles] = useState({});
  const [uploadError, setUploadError] = useState(false);
  const { bindOnJobCompleted } = useJobsOverlay();
  const { userPermissions } = useContext(UserPermissionsContext);

  const maxSize = process.env.REACT_APP_MAX_FILE_SIZE * 1024 * 1024;
  const minSize = 1;
  const onDrop =
    fileType =>
    (acceptedFiles = [], fileRejections = []) => {
      const onSkipFile = file => () =>
        setUploadFiles(prev => {
          delete prev[file.path];
          return { ...prev };
        });
      const onKeepFile = file => () => upload(keepFiles)({ file, fileType, setUploadFiles });
      const onReplaceFile = file => () => upload(replaceFiles)({ file, fileType, setUploadFiles });

      setUploadFiles(
        [
          ...acceptedFiles.map(file => ({
            path: file.path,
            name: file.name,
            size: file.size,
            progress: 0,
            onKeepFile: onKeepFile(file),
            onReplaceFile: onReplaceFile(file),
            onSkipFile: onSkipFile(file),
          })),
          ...fileRejections.map(({ file, errors }) => ({
            path: file.path,
            name: file.name,
            size: file.size,
            errors,
            failed: true,
          })),
        ].reduce((prev, curr) => ({ ...prev, [curr.path]: curr }), {})
      );

      if (acceptedFiles.length === 0) {
        return;
      }

      for (const i in acceptedFiles) {
        const file = acceptedFiles[i];
        uploadToBucket(file, loadJobs, bindOnJobCompleted);
      }
      onUploadCallbackFn();
    };

  const workpapersDropzone = useDropzone({
    accept: ['.xlsx', '.csv'],
    noKeyboard: true,
    noClick: true,
    maxSize,
    minSize,
    onDrop: onDrop('workpapers'),
    disabled: !userPermissions.includes('create_workpaper'),
  });

  return {
    workpapersDropzone,
    uploadFiles,
    uploadError,
    setUploadError,
  };
}
