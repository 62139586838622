import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { BTSpinner, BTButton, BTIcon, BTCheckbox, BTForm, BTComboBox, BTAlert } from '@btas/jasper';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import {
  DELETE_SOURCE_FILE,
  WKP_INPUT_FILE_IMPORT,
  WKP_CONFIG_PANEL_PAGINATION,
  FIND_AND_REPLACE_PROMPT,
} from '../../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import '../InputElementInspector/styles.scss';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { removeOriginUploadS3File, removeSourceFile } from '../InputElementInspector/apis';
import {
  useJobProcessor,
  JOB_PROCESSOR_STATUS_RETRY_CANCELLED,
  JOB_PROCESSOR_STATUS_CANCELLED,
} from '../../DataFlowJobsContext';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';
import { DATE, NUMERIC, TEXT } from '../shared/fieldTypes';
import { getSheetNames, getTempWorkingFileLocation, getWorkbookInformation } from '../FileDialog/apis';
import { removeUplodFilePermanently, validateIsPermanentDelete } from '../InputElementInspector/removeSourceFile';
import {
  FILE_COMPLETED_STATUS,
  FILE_IMPORT_RUNNING_STATUS,
  FILE_UPLOAD_RUNNING_STATUS,
} from './FilePropertiesStatusTypes';
import { PaginationContainer } from '../shared/PaginationContainer';
import { INSPECTOR_PAGE_SIZE } from '../shared/constants/pagination';
import { STEP_CANCEL } from '../InputElementInspector/useImportFile';
import { startJob } from '../../../_shared/jobs/apis';
import { DATAFLOW_FILE_METADATA_JOB_TYPE } from '../../../_shared/jobs/jobTypes';
import { INTEGRATION_REGEX, INTEGRATIONS_TYPE, SFTP_REGEX } from '../InputElementInspector/constants';
import Spinner from '../../../_shared/Spinner';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import UpdateFieldNameReferenceDialog from '../UpdateFieldNameReferenceDialog/UpdateFieldNameReferenceDialog';
import { syncElementStateReducer } from '../useDataFlowStateReducer/syncElementStateReducer';

const FilePropertiesPanel = ({
  dataFlowActions,
  dataFlowState,
  elementId,
  elementData,
  updateData,
  sourceFiles,
  setSourceFiles,
  isLoadingSourceFiles,
  uploadingElementId,
}) => {
  const { setWKPFileImportProperties, commitWorkingElement, setFindAndReplacePropDialog } = dataFlowActions;
  const { wkpFileImportProperties, id: dataflowId, taxPeriod, workingElement, findAndReplaceDialog } = dataFlowState;

  const { sourceFileUpload, showConfirmationModal, fileImport } = useContext(DataFlowEditorContext);
  const { cancelJob, bindOnStatusChanged } = useJobProcessor();
  const { updateImportFileState } = fileImport;
  const { uploadedFilesByInput, setUploadedFilesByInput } = sourceFileUpload;
  const [isLoading, setIsLoading] = useState(true);
  const [firsRender, setFirstRender] = useState(true);
  const inputFileImportFlag = isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT);
  const fileNameCharsLimit = 25;

  const deleteSourceFileFlag = isFeatureFlagEnabled(DELETE_SOURCE_FILE);
  const { url } = useRouteMatch();
  const history = useHistory();

  const total = elementData.fields?.length;
  const isPaginationEnabled = useRef(isFeatureFlagEnabled(WKP_CONFIG_PANEL_PAGINATION));
  const [page, setPage] = useState(1);

  const fields = useMemo(() => {
    const start = (page - 1) * INSPECTOR_PAGE_SIZE;
    const end = page * INSPECTOR_PAGE_SIZE;

    if (isPaginationEnabled.current === false) {
      return elementData.fields;
    }

    return elementData.fields?.slice(start, end);
  }, [elementData.fields, page]);

  const prevOriginalFileSize = useRef(null);
  const prevProcessedFileSize = useRef(null);

  const dataTypeOptions = [
    { label: 'Text', value: TEXT },
    { label: 'Number', value: NUMERIC },
    { label: 'Date', value: DATE },
  ];
  const getFileNameCharsLimit = 25;

  useEffect(() => {
    /*
      Prevent to update when the loading source files starts or
      we get a flickering issue
    */
    if (firsRender) {
      setFirstRender(false);
      return;
    }
    setIsLoading(isLoadingSourceFiles);
  }, [isLoadingSourceFiles, firsRender]);

  const getName = uploadedFile => {
    return uploadedFile?.name ?? null;
  };

  const truncatedName = (name, length) => {
    return name?.length > length ? `${name.substr(0, length)}...` : name;
  };

  const getTruncatedName = (uploadedFile, length) => {
    const fileName = getName(uploadedFile);
    return truncatedName(fileName, length);
  };

  const getSheetName = (sheetName, activeVersion) => {
    return activeVersion ? activeVersion.sheetName : sheetName;
  };

  const onFieldTypeChange = (pageIndex, { value }) => {
    const index = isPaginationEnabled.current ? (page - 1) * INSPECTOR_PAGE_SIZE + pageIndex : pageIndex;
    const newFields = [...elementData.fields];
    const { derived_date_format: derivedDateFormat } = newFields[index];
    //If field type is not a date, date format should be empty. Otherwise, check if date format has been derived. If not, use default date format.
    const newDateFormatValue =
      value === 'date' ? (derivedDateFormat ? derivedDateFormat : "yyyy-MM-dd'T'HH:mm:ss.SSSXXX") : '';

    newFields[index] = { ...elementData.fields[index], type: value, date_format: newDateFormatValue };

    updateData({ fields: newFields });
  };

  const getUploadedInputFile = () => {
    const uploadedFile = uploadedFilesByInput[elementId];

    return uploadedFile;
  };

  const getElementTitle = file => {
    return file?.name.length > fileNameCharsLimit ? getName(file) : '';
  };

  const onSelectDataWithinFile = () => {
    const fileExtension = elementData?.uploadedFile?.name.split('.').pop();
    const isCsvFile = fileExtension === 'csv';
    setWKPFileImportProperties({
      ...wkpFileImportProperties,
      popupDataDialog: true,
      fileName: elementData?.uploadedFile?.name,
      isCsvFile: wkpFileImportProperties?.isCsvFile || isCsvFile,
    });
  };

  const clearUploadInput = updateData => {
    updateData({
      ...elementData,
      integrationType: '',
      uploadedFile: undefined,
    });
    const newUploadedFiles = uploadedFilesByInput;
    delete newUploadedFiles[elementId];
    setUploadedFilesByInput(newUploadedFiles);
  };

  const onCloseJob = () => {
    const { bucket, location: path } = uploadedFilesByInput[elementId];
    const onStatusChange = status => {
      if (status === JOB_PROCESSOR_STATUS_RETRY_CANCELLED) {
        cancelJob({
          processId: elementId,
          callback: removeOriginUploadS3File,
          params: [dataflowId, { bucket, path, name: JOB_PROCESSOR_STATUS_RETRY_CANCELLED }],
        });
        clearUploadInput(updateData);
      }
    };
    bindOnStatusChanged(elementId, onStatusChange);
    cancelJob({
      processId: elementId,
      callback: removeOriginUploadS3File,
      params: [dataflowId, { bucket, path, name: JOB_PROCESSOR_STATUS_CANCELLED }],
    });
    clearUploadInput(updateData);
    const parentUrl = url.substring(0, url.lastIndexOf('/'));
    history.replace(`${parentUrl}`);
  };

  const onChangeAppendDataSource = checked => {
    const { sheetName } = sourceFiles[0];
    const { name } = elementData?.uploadedFile;
    const newFields = [...elementData?.fields];
    const appendDataSource = { appendSource: checked, fileName: name, sheetName, taxPeriod };
    if (checked) {
      const dataSourceCount = elementData?.fields?.reduce(
        (acum, field) =>
          field.name?.includes('Data Source') || field.original_name?.includes('Data Source') ? acum + 1 : acum,
        0
      );
      const dataSourceName = dataSourceCount > 0 ? `Data Source.${dataSourceCount}` : 'Data Source';
      newFields.push({ name: dataSourceName, type: 'text', date_format: 'MM/dd/yyyy' });
    } else {
      newFields.pop();
    }
    updateData({ ...elementData, fields: newFields, appendDataSource });
  };

  const editFile = async (
    sourceFileId,
    uploadedFile,
    sheetTabName,
    numHeaders,
    dataStartAt,
    fileBucket,
    fileKey,
    activeVersion
  ) => {
    const name = getName(uploadedFile);
    let path = fileKey,
      bucket = fileBucket,
      sheetNames = null,
      headerRows = numHeaders,
      headersStartAt = dataStartAt;
    const fileExtension = uploadedFile.name.split('.').pop();
    const isCsvFile = fileExtension === 'csv';

    if (activeVersion) {
      path = activeVersion.fileKey;
      bucket = activeVersion.bucket;
    }

    let jobResult = '';
    if (inputFileImportFlag) {
      // create job for polling
      let { jobId } = await startJob({
        entityId: workingElement.id,
        jobType: DATAFLOW_FILE_METADATA_JOB_TYPE,
        batchId: dataflowId,
        payload: {},
      });

      const integrationType = SFTP_REGEX.test(bucket)
        ? INTEGRATIONS_TYPE.SFTP
        : INTEGRATION_REGEX.test(bucket)
          ? INTEGRATIONS_TYPE.FIXED_ASSETS
          : null;

      jobResult = await getTempWorkingFileLocation(
        jobId,
        { path: uploadedFile.location, fileName: name },
        dataflowId,
        integrationType
      );
    }

    if (!isCsvFile) {
      path = uploadedFile.location; // get original xlsx path for reuse

      const result = inputFileImportFlag
        ? await getWorkbookInformation({ fileName: name, path: jobResult.processingPath }, dataflowId)
        : await getSheetNames({ bucket, path, name });

      sheetNames = result.sheetNames;
    }
    const savedHeaderRows = sourceFiles[0]?.headerRows;
    const savedHeadersStartAt = sourceFiles[0]?.headersStartAt;
    const savedSheetName = sourceFiles[0]?.sheetName;
    const savedTaxPeriod = sourceFiles[0]?.uploadedFile?.taxPeriod;

    headerRows = activeVersion?.headerRows || savedHeaderRows;
    headersStartAt = activeVersion?.headersStartAt || savedHeadersStartAt;

    setWKPFileImportProperties({
      ...wkpFileImportProperties,
      popupDataDialog: true,
      loading: false,
      fileName: name,
      isCsvFile,
      fileDialogTaxPeriod: savedTaxPeriod || taxPeriod,
      sheets: !isCsvFile
        ? sheetNames.map(sheet => {
            return { value: sheet.name, label: sheet.name };
          })
        : [],
      savedValues: {
        sheetName: getSheetName(sheetTabName, activeVersion) || savedSheetName,
        headerRows,
        headersStartAt,
      },
      sourceFileId,
      updateSourceFile: true,
    });
  };

  const displaHeaderDetails = (headersStartAt, headerRows, activeVersion, pendingVersion) => {
    if (activeVersion) {
      return activeVersion.headersStartAt && activeVersion.headerRows;
    }
    if (pendingVersion) {
      return pendingVersion.headersStartAt && pendingVersion.headerRows;
    } else {
      return headersStartAt && headerRows;
    }
  };

  const sheetHeaderDetails = (sheetName, headerRows, headersStartAt) => {
    return (
      <div>
        {sheetName && sheetName.length > 0 ? (
          <p className="wkp-input-sheet-details">
            Sheet: <span title={sheetName.length > 23 ? sheetName : ''}>{truncatedName(sheetName, 23)}</span> | Headers
            starts at: {headersStartAt} | Header row: {headerRows}
          </p>
        ) : (
          <p className="wkp-input-sheet-details">
            Headers starts at: {headersStartAt} | Header row: {headerRows}
          </p>
        )}
      </div>
    );
  };

  const deleteSourceFileModal = async (name, sourceFileId) => {
    if (deleteSourceFileFlag) {
      const deleteResp = await validateIsPermanentDelete(dataFlowState.id, elementId);
      if (deleteResp.permanentDeleteInfo && deleteResp.permanentDeleteInfo.length > 0) {
        //we have to check both file.id and sourceFileId because pending files will be retrieved
        //as source files versions and won't have id
        showConfirmationModal(
          'Delete Source File',
          `The source file '${name}' will be permanently deleted. Are you sure?`,
          'Delete',
          'Cancel',
          'warning',
          async () => {
            if (inputFileImportFlag) {
              const { deletePath } = elementData;
              await removeOriginUploadS3File(dataflowId, { path: deletePath });
              const newUploadedFiles = uploadedFilesByInput;
              delete newUploadedFiles[elementId];
              setUploadedFilesByInput(newUploadedFiles);
            }
            await removeSourceFile(sourceFileId, dataFlowState.id, elementId);
            await removeUplodFilePermanently(deleteResp.permanentDeleteInfo, elementData.integrationType.toLowerCase());
            const newSourceFiles = sourceFiles.filter(
              file => file.id !== sourceFileId && file.sourceFileId !== sourceFileId
            );

            setSourceFiles(newSourceFiles);
            updateData({
              fields: undefined,
              pendingSourceFileVersionId: undefined,
              import: undefined,
              ...(inputFileImportFlag && {
                uploadedFile: undefined,
                integrationType: '',
              }),
            });
            commitWorkingElement();
            history.replace(`/data-flows/${dataflowId}/editor`);
          }
        );
      } else {
        await removeSourceFile(sourceFileId, dataFlowState.id, elementId);
        const newSourceFiles = sourceFiles.filter(
          file => file.id !== sourceFileId && file.sourceFileId !== sourceFileId
        );
        setSourceFiles(newSourceFiles);
        if (newSourceFiles.length) {
          updateData({
            containsNewSourceFiles: false,
            isSourceFileDeleted: true,
            ...(inputFileImportFlag && {
              uploadedFile: undefined,
              integrationType: '',
            }),
          });
        } else {
          updateData({
            fields: undefined,
            pendingSourceFileVersionId: undefined,
            import: undefined,
            sourceFields: undefined,
            ...(inputFileImportFlag && {
              uploadedFile: undefined,
              integrationType: '',
            }),
          });
        }
        if (inputFileImportFlag) {
          const newUploadedFiles = uploadedFilesByInput;
          delete newUploadedFiles[elementId];
          setUploadedFilesByInput(newUploadedFiles);
        }
        commitWorkingElement();
        history.replace(`/data-flows/${dataflowId}/editor`);
      }
    }
  };

  const onCancelImport = () => {
    updateImportFileState(workingElement?.id, {
      currentStep: STEP_CANCEL,
    });
  };

  const renderUploadFilePill = () => {
    return (
      <>
        {elementData?.uploadedFile?.status === FILE_UPLOAD_RUNNING_STATUS && (
          <>
            <div className="wkp-upload-file-status">
              <div className="wkp-upload-file-elements">
                <div className="wkp-upload-file-spinner-wrapper">
                  <BTSpinner size="1x" />
                </div>
                <span
                  style={{ fontWeight: 'bold' }}
                  title={getElementTitle(getUploadedInputFile() || elementData?.uploadedFile)}
                >
                  Uploading '{getTruncatedName(getUploadedInputFile() || elementData?.uploadedFile, fileNameCharsLimit)}
                  '
                </span>
              </div>

              <button className="wkp-progress-bar-btn-close" type="button" onClick={onCloseJob}>
                <BTIcon icon="close" />
              </button>
            </div>
            <div className="wkp-upload-file-text">You can continue working while this file is being processed.</div>
          </>
        )}
        {elementData?.uploadedFile?.status === FILE_COMPLETED_STATUS && (
          <>
            <div className="wkp-upload-file-status">
              <div className="wkp-upload-file-elements">
                <div className="wkp-upload-file-success-icon">
                  <BTIcon icon="check-circle" />
                </div>
                <span
                  style={{ fontWeight: 'bold' }}
                  title={getElementTitle(getUploadedInputFile() || elementData?.uploadedFile)}
                >
                  {getTruncatedName(getUploadedInputFile() || elementData?.uploadedFile, fileNameCharsLimit)}
                </span>
              </div>
              <button className="wkp-progress-bar-btn-close" type="button" onClick={onCloseJob}>
                <BTIcon icon="close" />
              </button>
            </div>
            {![FILE_IMPORT_RUNNING_STATUS, FILE_COMPLETED_STATUS].includes(elementData?.import?.status) && (
              <div className="wkp-upload-file-text">Next, specify where your data is located within the file.</div>
            )}
            {![FILE_IMPORT_RUNNING_STATUS, FILE_COMPLETED_STATUS].includes(elementData?.import?.status) && (
              <BTButton
                btStyle="primary"
                className="wkp-select-data-within-file"
                id="select-data-within-file"
                onClick={onSelectDataWithinFile}
              >
                SELECT DATA WITHIN FILE
              </BTButton>
            )}
          </>
        )}
      </>
    );
  };

  const renderImportFilePill = () => {
    return (
      <>
        {elementData?.import?.status === FILE_IMPORT_RUNNING_STATUS && (
          <>
            <div className="wkp-upload-file-status">
              <div className="wkp-upload-file-elements">
                <div className="wkp-upload-file-spinner-wrapper">
                  <BTSpinner size="1x" />
                </div>
                <span style={{ fontWeight: 'bold' }} title={'Determining fields and data types'}>
                  Determining fields and data types
                </span>
              </div>

              <button className="wkp-progress-bar-btn-close" type="button" onClick={onCancelImport}>
                <BTIcon icon="close" />
              </button>
            </div>
            <div className="wkp-upload-file-text">This may take several minutes.</div>
            <div className="wkp-upload-file-text">You can continue working while this file is being processed.</div>
          </>
        )}
        {elementData?.import?.status === FILE_COMPLETED_STATUS && (
          <>
            <div className="wkp-upload-file-status">
              <div className="wkp-upload-file-elements">
                <div className="wkp-upload-file-success-icon">
                  <BTIcon icon="check-circle" />
                </div>
                <span style={{ fontWeight: 'bold' }} title={'Determining fields and data types'}>
                  Determining fields and data types
                </span>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const renderSourceFilesHeader = () => {
    const { fileSize: processedFileSize, uploadedFile } = sourceFiles[0];
    const { fileSize: originalFileSize, fileKey } = uploadedFile?.fileLocation;

    const fileExtension = fileKey.endsWith('.csv') ? 'csv' : 'xlsx';

    const isProcessedFileSizeChanged = processedFileSize !== prevProcessedFileSize.current;
    const isOriginalFileSizeChanged = originalFileSize !== prevOriginalFileSize.current;

    //Avoiding multiple logs when the component renders multiple times.
    if (isProcessedFileSizeChanged || isOriginalFileSizeChanged) {
      CustomLogger.pushLog(CustomLogger.operations.INGEST_FILE.GENERAL, {
        workflow_id: dataflowId,
        original_file_size: `${originalFileSize} bytes`,
        original_file_extension: fileExtension,
        processed_file_size: `${processedFileSize} bytes`,
        message: `Information from the imported file`,
      });
      prevProcessedFileSize.current = processedFileSize;
      prevOriginalFileSize.current = originalFileSize;
    }
    return sourceFiles.map(
      (
        { id, sourceFileId, activeVersion, pendingVersion, sheetName, headerRows, headersStartAt, bucket, fileKey },
        index
      ) => (
        <div key={index} className="wkp-source-file-name">
          <span
            title={
              getName(getUploadedInputFile() || elementData?.uploadedFile)?.length > getFileNameCharsLimit
                ? getName(getUploadedInputFile() || elementData?.uploadedFile)
                : ''
            }
          >
            {getTruncatedName(getUploadedInputFile() || elementData?.uploadedFile, getFileNameCharsLimit)}
          </span>{' '}
          {canEditWorkflow && (
            <BTButton
              aria-label="editFile"
              btStyle="link"
              id="df-edit-file-settings"
              title="Select data within file"
              onClick={() =>
                editFile(
                  id ?? sourceFileId,
                  getUploadedInputFile() || elementData?.uploadedFile,
                  sheetName,
                  headerRows,
                  headersStartAt,
                  bucket,
                  fileKey,
                  activeVersion
                )
              }
            >
              <BTIcon name="gear" />
            </BTButton>
          )}
          {deleteSourceFileFlag && !sourceFiles[0]?.error && canEditWorkflow ? (
            <BTButton
              btStyle="link"
              title="Unlink file from input block"
              onClick={() => deleteSourceFileModal(getName(elementData?.uploadedFile), id ?? sourceFileId)}
            >
              <BTIcon name="unlink" />
            </BTButton>
          ) : (
            ''
          )}
          {displaHeaderDetails &&
            sheetHeaderDetails(
              sheetName || activeVersion?.sheetName || pendingVersion?.sheetName
                ? getSheetName(sheetName, activeVersion)
                : undefined,
              (headerRows = activeVersion
                ? activeVersion.headerRows
                : pendingVersion
                  ? pendingVersion.headerRows
                  : headerRows),
              (headersStartAt = activeVersion
                ? activeVersion.headersStartAt
                : pendingVersion
                  ? pendingVersion?.headersStartAt
                  : headersStartAt)
            )}
          <BTCheckbox
            checked={elementData?.appendDataSource?.appendSource}
            disabled={!canEditWorkflow}
            id="checkbox"
            label="Append data source details to each row"
            onChange={e => onChangeAppendDataSource(e.target.checked)}
          />
        </div>
      )
    );
  };

  const renderFileColumns = () => {
    return (
      <>
        <h5>Columns</h5>
        <table className="wkp-fields-list">
          <thead>
            <tr>
              <th>Column</th>
              <th>Data type</th>
            </tr>
          </thead>
          <tbody>
            {fields?.map(({ original_name, name, type }, index) => (
              <tr key={index}>
                <td className="wkp-no-paddings">
                  <BTForm.FormGroup>
                    <div className="wkp-column-items wkp-cursor-default">{original_name || name}</div>
                  </BTForm.FormGroup>
                </td>
                <td className="wkp-no-paddings">
                  <BTComboBox
                    popOutMenu
                    defaultValue={dataTypeOptions.find(o => o.value === type)}
                    disabled={!canEditWorkflow}
                    maxMenuHeight={100}
                    options={dataTypeOptions}
                    value={dataTypeOptions.find(o => o.value === type)}
                    onChange={onFieldTypeChange.bind(null, index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isPaginationEnabled.current && total > INSPECTOR_PAGE_SIZE && (
          <PaginationContainer
            endIndex={page * INSPECTOR_PAGE_SIZE}
            page={page}
            setPage={setPage}
            startIndex={(page - 1) * INSPECTOR_PAGE_SIZE}
            totalFields={total}
          />
        )}
      </>
    );
  };

  const renderMultiUploadJobWarning = () => {
    return (
      <div className="wkp-inspector-warning">
        <BTAlert key={workingElement.id} btStyle={'warning'}>
          <p>Unable to upload another file until the current import is complete.</p>
        </BTAlert>
      </div>
    );
  };

  const displayFileDialogWithFindAndReplace = () => {
    if (findAndReplaceDialog?.show) {
      return <UpdateFieldNameReferenceDialog setElementData={updateData} />;
    } else {
      if (isLoading && !wkpFileImportProperties?.popupDataDialog) return <Spinner />;
      return renderSourceFileStatusPanel();
    }
  };

  useEffect(() => {
    if (isFeatureFlagEnabled(FIND_AND_REPLACE_PROMPT)) {
      // will trigger a sync only on first render after find and replace is ran
      if (findAndReplaceDialog?.triggerReRun) {
        // if will sync element state with updated configs for each element that was affected by find and replace
        let elements = dataFlowState.elements;
        let elementsToReplace = Object.keys(findAndReplaceDialog?.mapping);
        elementsToReplace.forEach(elementDataId => {
          if (elements[elementDataId]) {
            let elementMappingKey = Object.keys(findAndReplaceDialog.mapping[elementDataId]);
            elementMappingKey.forEach(key => {
              elements[elementDataId]['elementData'][key] = findAndReplaceDialog.mapping[elementDataId][key];
            });
          }
        });
        const newDataFlowState = { ...dataFlowState, elements: elements };
        // will update FindAndReplacePropDialog.triggerReRun to false after first render
        setFindAndReplacePropDialog({ ...findAndReplaceDialog, triggerReRun: false });
        syncElementStateReducer(newDataFlowState, 'sync-element-state');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findAndReplaceDialog?.triggerReRun, setFindAndReplacePropDialog]);

  const renderSourceFileStatusPanel = () => {
    if (
      uploadingElementId &&
      uploadingElementId !== workingElement?.id &&
      !workingElement?.elementData?.hasSourceFileUploaded
    ) {
      return renderMultiUploadJobWarning();
    }

    return (
      <div className="wkp-input-element-inspector">
        <h5>Source Data Files</h5>
        <div className="wkp-source-file-status">
          {!sourceFiles?.length && inputFileImportFlag && elementData?.uploadedFile && renderUploadFilePill()}
          {!sourceFiles?.length && inputFileImportFlag && renderImportFilePill()}
          {sourceFiles?.length > 0 && !sourceFiles[0]?.error && renderSourceFilesHeader()}
          {elementData?.fields?.length > 0 && sourceFiles?.length > 0 && !sourceFiles[0]?.error && renderFileColumns()}
        </div>
      </div>
    );
  };

  const canEditWorkflow = useCanEditWorkflow();
  if (isFeatureFlagEnabled(FIND_AND_REPLACE_PROMPT)) {
    return displayFileDialogWithFindAndReplace();
  } else {
    if (isLoading && !wkpFileImportProperties?.popupDataDialog) return <Spinner />;
    return renderSourceFileStatusPanel();
  }
};

export default FilePropertiesPanel;
