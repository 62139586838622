import GC from './SpreadSheets';
const { Sheets: spreadNS } = GC.Spread;
const { extend } = spreadNS.GC$;

export const spreadJSCommandsToAvoid = [
  'OpenPivotFilterDialogCmd',
  'createFilterSecondaryMenu',
  'createDialogForLabelFilter',
  'fieldSet',
  'CreatePivotFilterDialog',
  'CreatePivotViewsListDialog',
  'openValueFieldSettingDialogCmd',
  'openTextEditor',
  'showAllSheetsDialog',
  'gc.spread.contextMenu.allSheets',
  'shapeUnGroup',
  'shapeGroup',
  'contextMenuUnhideSheet',
  'bringShapeToFront',
  'bringShapeForward',
  'sendShapeToBack',
  'sendShapeBackward',
  'copy',
  'cut',
  'paste',
  'Designer.copy',
  'Designer.cut',
  'Designer.paste',
  'wkp.contextMenuMarkCellReviewed',
  'wkp.contextMenuUnmarkCellReviewed',
  'gc.spread.contextMenu.clearContents',
  'clear',
  'cellTag',
  'wkp.copySheet',
];
export function skipCommand(ss, command) {
  return ss.inMoveChart && (command.cmd === 'cutFloatingObjects' || command.cmd === 'pasteFloatingObjects');
}

let counter = Date.now();
export function applyTimestamp(cmd) {
  const username = 'username'; // This
  if (!cmd) {
    return;
  }
  // increment counter of timestamp
  counter++;

  // set timestamp
  cmd.timestamp = { sender: username };
  cmd.timestamp[username] = counter;
  return cmd;
}

////////////////////////////////////////////////////////////////////////////////////
// convert command json to SJS types

export function toRange(rg) {
  return new GC.Spread.Sheets.Range(rg.row, rg.col, rg.rowCount, rg.colCount);
}
export function ruleFromJson(ss, command, rule) {
  var x = null;
  if (command.sheetName) {
    var sheet = ss.getSheetFromName(command.sheetName);
    if (sheet) {
      var conditionalFormats = new GC.Spread.Sheets.ConditionalFormatting.ConditionalFormats(sheet);
      conditionalFormats.fromJSON({ rules: [rule] });
      x = conditionalFormats.getRule(0);
    }
  }
  return x;
}
export function toArray(obj) {
  if (Array.isArray(obj)) {
    return obj;
  }
  var a = [];
  var keys = Object.keys(obj);
  for (var i = 0; i < keys.length; i++) {
    a.push(obj[keys[i]]);
  }
  return a;
}
export function toShapeArray(spread, sheetName, obj) {
  var sheet = spread.getSheetFromName(sheetName);
  var a = [];
  var keys = Object.keys(obj);
  for (var i = 0; i < keys.length; i++) {
    var t = obj[keys[i]];
    if (typeof t === 'string') {
      a.push(t);
    } else {
      a.push(sheet.shapes.get(t.name));
    }
  }
  return a;
}
export function toPaperSize(data) {
  var p = new GC.Spread.Sheets.Print.PaperSize();
  p.fromJSON(data);
  return p;
}
var typeDict = {
  0: GC.Spread.Sheets.CellTypes.Base,
  1: GC.Spread.Sheets.CellTypes.Text,
  2: GC.Spread.Sheets.CellTypes.ColumnHeader,
  3: GC.Spread.Sheets.CellTypes.RowHeader,
  4: GC.Spread.Sheets.CellTypes.Corner,
  5: GC.Spread.Sheets.CellTypes.CheckBox,
  6: GC.Spread.Sheets.CellTypes.Button,
  7: GC.Spread.Sheets.CellTypes.ComboBox,
  8: GC.Spread.Sheets.CellTypes.HyperLink,
  11: GC.Spread.Sheets.CellTypes.RadioButtonList,
  12: GC.Spread.Sheets.CellTypes.CheckBoxList,
  13: GC.Spread.Sheets.CellTypes.ButtonList,
  16: GC.Spread.Sheets.CellTypes.RangeTemplate,
  18: GC.Spread.Sheets.CellTypes.DataObject,
};
export function cellTypeFromJSON(cellType) {
  var typeName = cellType.typeName || cellType.type;
  // type: build-in cell type in previous version, typeName: build-in cell type in current version
  var celltypeClass = typeDict[typeName] || GC.Spread.Sheets.getTypeFromString(typeName);
  if (celltypeClass) {
    var resultCellType = new celltypeClass();
    resultCellType.fromJSON(cellType);
    return resultCellType;
  }
  return null;
}

export function protectSheets(ss) {
  let sheetCount = ss.getSheetCount();
  for (let i = 0; i < sheetCount; i++) {
    let sheet = ss.getSheet(i);
    sheet.options.isProtected = true;

    sheet.options.protectionOptions = {
      allowSelectLockedCells: false,
      allowSelectUnlockedCells: false,
      allowSort: false,
      allowFilter: false,
      allowEditObjects: false,
      allowInsertColumns: false,
      allowInsertRows: false,
      allowDeleteColumns: false,
      allowDeleteRows: false,
    };
  }
}

export function getSheetOptions(sheet) {
  var sdata = {};
  for (var p in sheet.options) {
    if (sheet.options.hasOwnProperty(p)) {
      var v = sheet.options[p];
      var tmpValue = v;
      if (p === 'gridline') {
        tmpValue = {};
        var v_color = v.color,
          v_showHorizontalGridline = v.showHorizontalGridline,
          v_showVerticalGridline = v.showVerticalGridline;
        tmpValue.color = v_color;
        if (v_showHorizontalGridline === false) {
          tmpValue.showHorizontalGridline = v_showHorizontalGridline;
        }
        if (v_showVerticalGridline === false) {
          tmpValue.showVerticalGridline = v_showVerticalGridline;
        }
      }
      if (p === 'sheetAreaOffset') {
        tmpValue = {};
        if (v.left) {
          tmpValue.left = v.left;
        }
        if (v.top) {
          tmpValue.top = v.top;
        }
      }
      if (p !== '_ps' && tmpValue !== null && tmpValue !== undefined) {
        /* NOSONAR: CollapsibleIfStatements */
        sdata[p] = tmpValue;
      }
    }
  }
  sdata.theme = sheet.currentTheme().name();
  return sdata;
}

export function preprocessCommand(ss, command) {
  var sheet = ss.getActiveSheet();
  if (sheet.name() === command.sheetName) {
    command.activeRowIndex = sheet.getActiveRowIndex();
    command.activeColIndex = sheet.getActiveColumnIndex();

    if (!command.selections) {
      command.selections = sheet.getSelections();
    }
    if (command.cmd === 'clipboardPaste' && command.clipboardImage) {
      const changesKey = getChangesKey(command.sheetName);
      const changes = command[changesKey];
      const shapeChanges = changes && changes.shapeChanges;
      if (shapeChanges) {
        const shapeSizes = {};
        shapeChanges.forEach(c => {
          if (c.value && c.value.type === 'addPictureShape') {
            let pn = c.value.value;
            let sp = sheet.shapes.get(pn);
            if (sp) {
              shapeSizes[pn] = { width: sp.width(), height: sp.height() };
            }
          }
        });
        command.shapeSizes = shapeSizes;
      }
    }
  } else {
    command.targetActiveRowIndex = sheet.getActiveRowIndex();
    command.targetActiveColIndex = sheet.getActiveColumnIndex();
    command.targetSelections = sheet.getSelections();
  }
  return command;
}

export function toJSONImp(GC, spreadSheet, command) {
  if (!command) {
    return command;
  }
  if (typeof command === 'string') {
    return command;
  }
  if (command._inToJSON) {
    return null;
  }
  let cmd = Array.isArray(command) ? new Array(command.length) : {};
  command._inToJSON = true;
  for (let p in command) {
    if (command.hasOwnProperty(p)) {
      let commandValue = command[p];
      if (!commandValue) {
        cmd[p] = commandValue;
      } else {
        let valType = commandValue.constructor;
        if (valType === GC.Spread.Sheets.Worksheet) {
          if (command.cmd === 'Designer.headerSetting') {
            cmd[p] = commandValue.toJSON();
          } else {
            cmd[p] = commandValue.name();
          }
        } else if (p === 'timestamp') {
          cmd[p] = commandValue;
        } else if (p === 'slicerData') {
          cmd['slicerData'] = commandValue.getTable().name();
        } else if (p === 'clipboardFloatingObjectData') {
          let x = [];
          commandValue.each(d => {
            x.push(d.toJSON());
          });
          cmd[p] = x;
        } else if (commandValue.toJSON) {
          let commandValueJson = null;
          if (
            commandValue instanceof GC.Spread.Sheets.Tables.Table ||
            commandValue instanceof GC.Spread.Sheets.ConditionalFormatting.Condition ||
            commandValue instanceof GC.Spread.Sheets.ConditionalFormatting.ConditionRuleBase
          ) {
            commandValueJson = commandValue.toJSON();
          } else {
            commandValueJson = commandValue.toJSON({});
          }

          if (commandValueJson && typeof commandValueJson === 'object' && commandValueJson.typeName === undefined) {
            commandValueJson.typeName = commandValue.constructor.name;
          }

          if (
            commandValueJson !== null &&
            commandValueJson !== undefined &&
            typeof commandValueJson !== 'string' &&
            valType !== Number &&
            valType !== String &&
            valType !== Date &&
            valType !== Boolean
          ) {
            cmd[p] = toJSONImp(GC, spreadSheet, commandValueJson);
          } else {
            cmd[p] = commandValueJson;
          }
        } else if (valType !== Number && valType !== String && valType !== Date && valType !== Boolean) {
          cmd[p] = toJSONImp(GC, spreadSheet, commandValue);
        } else if (
          typeof commandValue !== 'function' &&
          (!cmd['fromSheet'] || (p !== 'clipboardHtml' && p !== 'clipboardImage' && p !== 'clipboardText'))
        ) {
          cmd[p] = commandValue;
        }
      }
    }
  }

  delete command._inToJSON;
  delete cmd._inToJSON;
  delete cmd.cachedActions;
  return cmd;
}

export function toJSON(GC, spreadSheet, command) {
  const cmd = beforeToJSON(command);
  let t = toJSONImp(GC, spreadSheet, cmd);

  if (cmd.cmd === 'Designer.insertSheet') {
    let sheet = spreadSheet.getActiveSheet();
    t = {
      cmd: 'insertSheet',
      sheetName: sheet.name(),
      sheetIndex: spreadSheet.getActiveSheetIndex(),
      data: getSheetOptions(sheet),
    };
  }
  delete t._oldPosition;
  delete t.sheetNames;
  delete t[`changes${command.sheetName}`];
  delete t.vnt;
  delete t.z3;
  for (let key in t) {
    if (key.startsWith('changes') && Object.keys(t[key]).length === 0) {
      delete t[key];
    }
  }
  return t;
}

export function getChangesKey(sheetName) {
  return 'changes' + sheetName;
}

export function getFormulaChangesKey(sheetName) {
  return sheetName + 'formulas';
}

export function beforeToJSON(command) {
  let cmd = extend({}, command);
  let sheetNames = cmd.sheetNames || [command.sheetName];
  if (sheetNames && sheetNames.length > 0) {
    for (let i = 0; i < sheetNames.length; i++) {
      let sheetName = sheetNames[i];
      const changesKey = getChangesKey(sheetName);
      const formulaChangesKey = getFormulaChangesKey(sheetName);
      if (changesKey) delete cmd[changesKey];
      if (formulaChangesKey) delete cmd[formulaChangesKey];
    }
  }
  return cmd;
}
