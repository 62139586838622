import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTokenInfo, logout } from '../../_shared/auth';
import { setDataToLocalStorage, getDataFromLocalStorage, removeDataFromLocalStorage } from '../../_shared/storage';
import { getOneTimeToken, redirectToLogin, refreshExpiredToken } from '../../_shared/jwtAuth';
import { getUserInfoV2, getAuthUrlParameters, scaleProvision } from '../../_shared/jwtAuth';
import { EnviromentUrl, numberOfTokenRefreshesToRedirectOnLogin } from '../../../configs/params';
import { displayApplicationBrowserConsoleLogs } from '../../_shared/consoleLogger';
import { getTokenExpirationTime } from '../../_shared/storage';
import { isTokenExpired, ONE_SECOND_MS } from '../_shared/authUtils';
import { refreshToken, retryFunction } from '../_shared/authUtils';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import { AUTH_LOCK } from '../_shared/authUtils';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_CM_INTEGRATION } from '../../../constants/featureFlags';
import LDClient from '../../../components/_shared/launchDarklyClient';

let tabTokenRefreshCounter = 0;
const tabId = Date.now().toString();

CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: `Tab Id: ${tabId})` });

window.addEventListener('beforeunload', function (event) {
  CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
    message: 'Removing masterTabId from local storage',
  });
  removeDataFromLocalStorage('masterTabId');
});

const useAuth = () => {
  displayApplicationBrowserConsoleLogs();
  const { pathname } = useLocation();
  const [user, setUser] = useState();
  const ldClient = LDClient.getInstance();
  const [isError, setIsError] = useState(false);
  const TOKEN_REFRESH_COUNT = 'tokenRefreshCount';
  const resetRefreshCounterEvents = ['click', 'keypress', 'onkeydown'];

  if (!getDataFromLocalStorage('masterTabId')) {
    CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
      message: `Setting masterTabId: ${tabId}`,
    });
    setDataToLocalStorage('masterTabId', tabId);
    CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: `This is Master Tab now: ${tabId}` });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetTokenRefreshCount = () => {
    setDataToLocalStorage(TOKEN_REFRESH_COUNT, 0);
    tabTokenRefreshCounter = 0;
  };

  async function checkAndRefreshLogic() {
    if (!getDataFromLocalStorage('masterTabId')) {
      CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: `Setting masterTabId: ${tabId}` });
      setDataToLocalStorage('masterTabId', tabId);
      CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: `This is Master Tab now: ${tabId}` });
    }
    setIsError(false);
    const tokenRefreshCount = parseInt(getDataFromLocalStorage(TOKEN_REFRESH_COUNT));
    if (tokenRefreshCount >= parseInt(numberOfTokenRefreshesToRedirectOnLogin)) {
      CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: 'Refreshing token hit max count' });
      logout();
      redirectToLogin();
      return;
    }
    const tokenExpirationTime = getDataFromLocalStorage('tokenExpirationTime');

    CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
      message: `Token with expiration time ${new Date(parseInt(tokenExpirationTime))} is expired ${isTokenExpired()}}`,
    });
    if (!tokenExpirationTime || isTokenExpired()) {
      CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: 'Token is expired' });
      await checkAndRefreshToken();
      scaleProvision();
    }
    const tokenInfo = getTokenInfo();
    if (!tokenInfo) {
      redirectToLogin();
      return;
    }
    setTimeout(checkAndRefreshLogic, 30 * ONE_SECOND_MS);
  }

  const setUserAndTabToken = function (data) {
    setUser(data);
    tabTokenRefreshCounter = parseInt(getDataFromLocalStorage(TOKEN_REFRESH_COUNT));
    tabTokenRefreshCounter++;
    setDataToLocalStorage(TOKEN_REFRESH_COUNT, tabTokenRefreshCounter);
  };

  async function checkAndRefreshToken() {
    navigator.locks.request(AUTH_LOCK, async lock => {
      console.log('LOCK INFO', lock);
      if (getDataFromLocalStorage('masterTabId') === tabId) {
        CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
          message: 'This is Master Tab and refreshToken is happening here',
        });
        await refreshToken(setUserAndTabToken, getUserInfoV2, refreshExpiredToken, getTokenExpirationTime);
      }
    });
  }
  function forwardReturnUrl(returnUrl) {
    CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: `EnvironmentUrl:${EnviromentUrl} ` });
    let target;
    if (returnUrl.includes(EnviromentUrl.toString())) {
      if (isFeatureFlagEnabled(WKP_CM_INTEGRATION)) {
        target = `${document.baseURI}client-selection?redirectUrl=${decodeURIComponent(returnUrl)}`;
      } else {
        target = decodeURIComponent(returnUrl);
      }
      window.location.replace(target);

      return;
    } else {
      logout();
      redirectToLogin();
      return;
    }
  }
  useEffect(() => {
    (async function () {
      const { returnUrl, authToken } = getAuthUrlParameters();
      const decodedPathName = decodeURIComponent(pathname);
      const isLoginPage = decodedPathName.includes('auth/login');
      if (isLoginPage) {
        if (!returnUrl) {
          setIsError(true);
          redirectToLogin();
          return;
        }
        if (!authToken) {
          forwardReturnUrl(returnUrl);
        }
        try {
          console.log('Auth token:', authToken);
          console.log('Return Url:', returnUrl);
          const result = await retryFunction(getOneTimeToken, 3, authToken);

          if (result.notAuthorized) {
            redirectToLogin(returnUrl);
            return;
          } else if (result.data) {
            CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, {
              message: 'One time token data exists. Proccess complete',
            });
            if (!ldClient.isInitDone) {
              await ldClient.init();
            }
            const flags = ldClient.getFlags();
            if (flags?.length === 0) {
              await ldClient.setFlags();
            }
            forwardReturnUrl(returnUrl);
          }
          setIsError(true);
        } catch (error) {
          setIsError(true);
        }
      }

      CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: `Setting masterTabId:${tabId} ` });
      setDataToLocalStorage('masterTabId', tabId);
      CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: `This is Master Tab now: ${tabId}` });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);
  useEffect(() => {
    (async function () {
      setDataToLocalStorage(TOKEN_REFRESH_COUNT, 0);
      const tokenInfo = getTokenInfo();
      if (tokenInfo) {
        CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: 'Token exist on page load' });
        setUser(tokenInfo);
        await checkAndRefreshLogic();
        resetRefreshCounterEvents.forEach(evt => window.addEventListener(evt, resetTokenRefreshCount));
        if (!isTokenExpired()) {
          await getUserInfoV2().catch(error => console.log('Error trying to get Product Codes from User Info'));
        }
      }
      const isLoginPage = pathname.includes('auth/login');
      if (!isLoginPage && !tokenInfo) {
        CustomLogger.pushLog(CustomLogger.operations.REFRESH_TOKEN, { message: 'Token does not exist on home page' });
        await checkAndRefreshLogic();
        resetRefreshCounterEvents.forEach(evt => window.addEventListener(evt, resetTokenRefreshCount));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    user,
    isError,
  };
};
export { useAuth };
