import * as fetch from '../../../_shared/fetch';
import { dataFlowServiceHostName, workpapersSpreadsheetModelHostName } from '../../../../configs/params';

async function createWorkpaperSpreadsheetModel(workpaperId) {
  console.log('*****createWorkpaperSpreadsheetModel*****');
  const path = `${workpapersSpreadsheetModelHostName}/file/create`;
  return await fetch.post(path, {
    body: JSON.stringify({
      workpaperId,
    }),
  });
}

export async function createWorkpaper(workpaperId) {
  const response = await createWorkpaperSpreadsheetModel(workpaperId);
  const data = await response.json();
  console.log('createWorkpaper->response->', response.status);
  return data.id;
}

export async function createDataFlow() {
  const response = await fetch.post(`${dataFlowServiceHostName}/api/v1/data-flows`);

  const data = await response.json();

  return data.id;
}
