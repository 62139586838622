import { useEffect, useRef } from 'react';
import GC from '../../../../SpreadSheets';

export default function useStatusBar({
  isCommandsSaving,
  isDataReferenceSaving,
  hasPendingChanges,
  hasCommandsPending,
}) {
  const statusBarRef = useRef();
  const statusBarAddedRef = useRef(false);

  const createStatusBarItem = (itemName, value, menuContent) => {
    const statusItem = new GC.Spread.Sheets.StatusBar.StatusItem(itemName, {
      menuContent,
      value,
      itemName,
      showStatusInContexMenu: false,
    });
    const statusItemDiv = document.createElement('div');
    statusItemDiv.innerHTML = statusItem.value;

    statusItem.onCreateItemView = container => container.appendChild(statusItemDiv);
    statusItem.updateText = text => (statusItemDiv.innerText = text);

    return statusItem;
  };

  const addStatusBar = spread => {
    try {
      if (!statusBarAddedRef.current) {
        const items = [
          createStatusBarItem('loadingStatus', 'Loading: 0%', 'Loading Status'),
          createStatusBarItem('savingStatus', 'Saved', 'Saving Status'),
        ];
        const gcStatusBarElements = document.getElementsByClassName('gc-statusBar');
        if (document.querySelector('.gc-statusbar') && gcStatusBarElements.length > 0) {
          statusBarRef.current = GC.Spread.Sheets.StatusBar.findControl(gcStatusBarElements);
          if (!statusBarRef.current.get('loadingStatus')) {
            statusBarRef.current.add(items[0]);
            statusBarRef.current.add(items[1]);
          }
        } else {
          statusBarRef.current = new GC.Spread.Sheets.StatusBar.StatusBar(document.querySelector('.bottom-panels'), {
            items,
          });
          statusBarRef.current.bind(spread);
        }
        statusBarAddedRef.current = true;
      }
    } catch {}
  };

  const getStatusBarItem = itemName => statusBarRef.current?.get(itemName);

  useEffect(() => {
    const savingStatus = getStatusBarItem('savingStatus');

    if (savingStatus) {
      let savingStatusText = 'Saved';

      if (isCommandsSaving || isDataReferenceSaving) {
        savingStatusText = 'Saving...';
      } else if (hasPendingChanges) {
        savingStatusText = 'Unsaved changes';
      }

      savingStatus.updateText(savingStatusText);
    }
  }, [isCommandsSaving, isDataReferenceSaving, hasPendingChanges, hasCommandsPending]);

  return {
    addStatusBar,
    getStatusBarItem,
    statusBarAddedRef,
  };
}
