export const BroadcastChannels = {
  Default: 'default-channel',
  SourceUpdateReferences: 'source-update-references',
  TargetCreatedReference: 'target-created-reference',
  ClientSync: 'client-sync-channel',
};

export const EventTypes = {
  DataLinkSourceUpdated: 'data-link-source-updated',
  DataLinkCreated: 'data-link-created',
  ClientChanged: 'client-changed',
};
