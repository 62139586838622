const CommandType = {
  InsertEntireRow: 'gc.spread.contextMenu.insertRows',
  InsertEntireColumn: 'gc.spread.contextMenu.insertColumns',
  DeleteEntireRow: 'gc.spread.contextMenu.deleteRows',
  DeleteEntireColumn: 'gc.spread.contextMenu.deleteColumns',
  InsertPartialRow: 'Designer.insertRows',
  InsertCellsDown: 'Designer.insertDownCells',
  InsertCellsRight: 'Designer.insertRightCells',
  DeleteCellsLeft: 'Designer.deleteLeftCells',
  DeleteCellsUp: 'Designer.deleteUpCells',
  ClearCellValue: 'clearValues',
  DragDrop: 'dragDrop',
  CellPasting: 'clipboardPaste',
  DragFill: 'fill',
  RenameSheet: 'renameSheet',
  InsertRowOrColumn: 'InsertRowOrColumn',
  DeleteEntireRowColumn: 'deleteEntireRowOrColumn',
};

export { CommandType };
